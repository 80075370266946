.c-history-students {
  padding: $inuit-global-spacing-unit-massive - $inuit-global-spacing-unit-small 0;
  overflow: hidden;

  &__title {
    text-align: center;
  }

  &__wrapper-people {
    margin-top: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
    margin-left: 0;
    padding: $inuit-global-spacing-unit-huge 0 $inuit-global-spacing-unit-massive - $inuit-global-spacing-unit;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
  }

  &__student {
    &--box-1 {
      margin-top: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;

      .box-student {
        margin: 0 auto;
      }
    }

    &--box-2 {
      margin-top: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;
      padding-left: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-big;
      padding-bottom: $inuit-global-spacing-unit-huge;
    }

    &--box-3 {
      margin-top: -$inuit-global-spacing-unit-large;
      transform: translateX(50%);
    }
  }

  @include mq($until:medium) {
    &__wrapper-people {
      background-image: none !important;
      padding: $inuit-global-spacing-unit-great 0 0;
    }

    &__student {
      margin: 0;
      padding: 0;
      transform: translateX(0);
    }
  }
}
