.c-custom-list {
  &--bigger {
    .c-custom-list__icon-wrap {
      flex-basis: $inuit-global-spacing-unit-great;
      width: $inuit-global-spacing-unit-great;
    }

    .c-custom-list__text {
      padding-left: $inuit-global-spacing-unit-big;
    }

    .c-custom-list__item {
      margin-bottom: $inuit-global-spacing-unit-large;
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;

    &--inline {
      display: inline-block;
      vertical-align: middle;
      margin-right: $inuit-global-spacing-unit;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__icon-wrap {
    flex-shrink: 0;
    flex-basis: $inuit-global-spacing-unit-large;
    width: $inuit-global-spacing-unit-large;
  }

  &__icon {
    max-width: 100%;
  }

  &__text {
    padding-left: $inuit-global-spacing-unit-tiny;
  }
}
