/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  @include mq($from: large) {
    justify-content: flex-end;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__wcag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 1.75rem 0;
    margin-top: -0.5rem;

    svg {
      path {
        fill: black;
      }
    }
  }

  &--mobile {
    background-color: color_var(bg, 1);
    width: 100%;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big 0
      $inuit-global-spacing-unit-huge;
    position: absolute;
    z-index: 10;
    top: 80px;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease-in-out, transform 0.4s ease-out;
    max-height: calc(100vh - 80px);
    overflow-y: auto;

    .c-main-nav {
      flex-direction: column;
      padding-bottom: 50px;
    }

    &.is-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 0.4s ease-in-out, transform 0.3s ease-in-out;
    }

    .c-main-nav__item {
      width: 100%;
      margin: 0;
      text-align: left;

      .menu-item-has-children.is-clicked:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .c-main-nav__link {
      color: color_var(font, 1) !important;
      display: flex;
      align-items: center;

      svg {
        margin-right: $inuit-global-spacing-unit-tiny;
      }

      &.menu-item-has-children {
        position: relative;
        display: inline-block;

        &:after {
          content: '';
          position: absolute;
          right: -$inuit-global-spacing-unit;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-left: $inuit-global-spacing-unit-micro solid transparent;
          border-right: $inuit-global-spacing-unit-micro solid transparent;
          border-top: $inuit-global-spacing-unit-tiny solid color_var(font, 1);
        }
      }
    }

    .c-button--primary {
      color: color_var(font, 3) !important;
      width: auto;
      display: inline;

      .c-main-nav__sublink {
        color: color_var(font, 3) !important;
      }
    }

    .c-main-nav__dropdown {
      display: none;
    }

    .c-main-nav__list {
      padding-left: $inuit-global-spacing-unit-big;
    }

    .c-main-nav__subitem {
      padding-left: 0;

      &.menu-item-has-children {
        border: 1px solid color_var(font, 4);
        border-radius: $global-radius;
        padding: $inuit-global-spacing-unit-big;
        margin-top: $inuit-global-spacing-unit;
      }

      .c-main-nav__subitem {
        margin-top: $inuit-global-spacing-unit;
      }
    }

    .c-main-nav__sublink {
      padding: 0;
    }
  }

  &__multisite-link {
    position: relative;
    min-height: 50px;
    display: flex;
    align-items: center;

    &:before {
      @include absolutePseudoContent();
      width: 100vw;
      height: 100%;
      left: 0;
      margin-left: -$inuit-global-spacing-unit-huge;
      background-color: color-var(bg, 7);
    }
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0;
  margin-left: $inuit-global-spacing-unit-big;

  .is-clicked + .c-main-nav__dropdown {
    display: flex;
  }

  @include mq($from: large) {
    margin-left: 0;
    &:hover .c-main-nav__dropdown {
      display: flex;
    }
  }
}

.c-main-nav__link {
  display: block;
  text-decoration: none;
  white-space: nowrap;

  &:not(.c-button) {
    padding: $inuit-global-spacing-unit-big 0;
    color: color-var(font, 3);
    font-weight: 600;
    font-family: $global-heading-font-family;
    transition: opacity 0.25s ease-in-out;
    font-size: 0.95rem !important;
    @include mq($until: medium) {
      padding: $inuit-global-spacing-unit 0;
      margin: 0;
    }
    
    @media (min-width: 64em) and (max-width: 79.99em) {
    // @include mq($until: xlarge, $from: large) {
      font-size: 0.875rem !important;
    }

    &:hover {
      color: color-var(font, 3);
      opacity: 0.5;
    }
  }
}

.current-menu-item .c-main-nav__link {
  color: color-var(font, 4);
}

.c-main-nav__dropdown {
  @include mq($from: large) {
    display: none;
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    max-width: 100%;
    background: color-var(bg, 1);
    margin-right: $inuit-global-spacing-unit;
    box-shadow: $global-shadow;
  }
  &-footer {
    padding: 20px;
    text-align: center;
    background: color-var(bg, 6);
  }
}

.c-main-nav__list,
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: 0.5em;
  text-decoration: none;

  &:hover {
    color: color-var(font, 4);
  }
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: color-var(border);
}

.c-main-nav__subitem.menu-item-has-children {
  text-transform: uppercase;
  font-weight: 600;
  font-family: $global-heading-font-family;
  @include inuit-font-size($inuit-font-size-h5);
  color: color_var(font, 4) !important;
  padding-top: $inuit-global-spacing-unit-large;
  padding-bottom: $inuit-global-spacing-unit;
  @include mq($from: medium) {
    &:not(:first-child) {
      border-left: 1px solid color-var(border, 1);
    }
  }

  .c-main-nav__subitem {
    text-transform: none;
    font-weight: 400;
    font-family: $global-body-font-family;
    color: color_var(font, 1) !important;

    @include mq($from: medium) {
      &:first-child {
        padding-top: $inuit-global-spacing-unit;
      }
    }
  }

  ul {
    margin: 0;
  }
}
