.c-filter-content {

  &--disable-p-bottom {
    padding-bottom: 0;
  }

  &--disable-p-top {
    padding-top: 0;
  }

  &--shadow {
    margin: $inuit-global-spacing-unit-huge 0;
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);

    .c-content-tabs--inner-content {
      margin: 0 -$inuit-global-spacing-unit;

      .c-content-tabs__container--content {
        box-shadow: none;
      }
    }

    .c-filter-content__title {
      margin-left: $inuit-global-spacing-unit;
      margin-right: $inuit-global-spacing-unit;
    }
  }

  &__container {
    &--disable-padding {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-huge;
    text-align: center;
  }

  &__form-wrapper {
    padding-bottom: $inuit-global-spacing-unit-tiny;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__tax-wrapper {
    display: none;

    &.is-active {
      display: block;
    }
  }

  @include mq($until: medium) {
    overflow: hidden;

    &__container {
      padding: 0 $inuit-global-spacing-unit;

      &--disable-padding {
        padding: 0;

        .c-filter-content__form-wrapper {
          padding: 0 $inuit-global-spacing-unit;
        }
      }
    }
  }
}
