.c-vue-filters {
  @include mq($until: medium) {
    position: fixed;
    width: calc(100vw - 20px);
    height: calc(90vh - 20px);
    top: 10px;
    left: 10px;
    overflow-y: scroll;
    z-index: 1000 !important;
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-small;
    box-shadow: $global-shadow;

    // &:before {
    //   @include absolutePseudoContent();
    //   width: 100%;
    //   height: 100%;
    //   background-color: color-var(bg, 11);
    //   opacity: 0.8;
    //   z-index: -1;
    // }
  }
}
