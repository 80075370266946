.c-event-information {
  &--add-padding {
    padding-left: $inuit-global-spacing-unit;
    padding-right: $inuit-global-spacing-unit;
  }

  &__container {
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-great;
    border-radius: $global-radius;
    background: color-var(bg, 15);
  }

  &__title {
    display: block;
    margin-bottom: $inuit-global-spacing-unit-large;
    font-family: $global-heading-font-family;
    font-size: $inuit-global-font-size;
    font-weight: $global-heading-font-weight;
    line-height: $inuit-global-line-height;
  }

  &__right-column {
    margin-left: auto;
  }

  @include mq($until:large) {
    justify-content: flex-start;

    &__right-column {
      max-width: 100%;
      margin-top: $inuit-global-spacing-unit-big;
      width: 100%;
    }
  }

  @include mq($until:medium) {
    &__container {
      padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-big;
    }
  }
}
