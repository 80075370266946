.c-events-list {
  &--disable-p-bottom {
    padding-bottom: 0;
  }

  &--small-p-top {
    padding-top: $inuit-global-spacing-unit-small;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-great;
  }
}
