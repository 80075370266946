.c-basic-section {
  padding: $inuit-global-spacing-unit-great 0;

  @include mq($until: medium) {
    padding: $inuit-global-spacing-unit 0;

    p {
      margin-bottom: $inuit-global-spacing-unit-small;
    }
  }

  &--disable-padding-top-btm {
    padding: 0;
  }

  .wp-block-embed__wrapper {
    padding: 0 $inuit-global-spacing-unit;
  }

  .c-embed--video {
    height: 0;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &--shadow {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + #{$inuit-global-spacing-unit-massive});
      position: absolute;
      top: -$inuit-global-spacing-unit-massive;
      left: 0;
      background: url('../assets/images/svg/fala-blue.svg') no-repeat left top;
      z-index: -1;
    }

    .c-basic-section__container {
      padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit $inuit-global-spacing-unit;
      box-shadow: 0 0 $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
      background-color: color-var(bg, 1);
      border-radius: $global-radius;
    }
  }

  &--inner {
    padding: 0;

    .c-basic-section__container {
      padding: 0;
      box-shadow: none;
    }
  }

  &__content-inner-block {
    &--double-column {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    &--smaller {
      max-width: 770px;
      margin: 0 auto;
    }
  }

  &__btns-wrapper {
    text-align: right;
  }

  &__price-cell {
    text-align: right;

    .c-table-pricelist__single-column {
      display: inline-block;
      margin: 0;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  @include mq($until:medium) {
    &__content-inner-block {
      .c-contact-form--mobile-full {
        margin: 0 -$inuit-global-spacing-unit;
      }
    }

    &__price-cell {
      width: 100%;

      .c-table-pricelist__single-column {
        display: block;
      }

      .c-table-pricelist__label {
        display: inline-block;
        width: auto;
        padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-small;
      }

      .c-table-pricelist__price {
        display: block;
      }

    }

    &__btns-wrapper {
      .c-basic-section__cta {
        display: block;
        width: 100%;
      }
    }
  }
}
