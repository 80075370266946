.c-popup-form {
  padding-bottom: $inuit-global-spacing-unit-huge;

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    background-color: rgba(color-var(bg, 11), 0.8);
    overflow: hidden;
    overflow-y: auto;
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__wrapper {
    max-width: 970px;
    margin: $inuit-global-spacing-unit-huge auto 0 auto;
    padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-massive;
    border-radius: $global-radius;
    background-color: color-var(bg, 1);
    box-shadow: 0 0 $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  }

  &__btn-close {
    position: absolute;
    top: $inuit-global-spacing-unit-large;
    right: $inuit-global-spacing-unit-large;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    transition: $global-transition;

    &:hover {
      svg * {
        fill: color-var(font, 5);
      }
    }
  }

  @include mq($until:medium) {
    &__container {
      overflow-y: auto;
    }

    &__wrapper {
      margin: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-tiny 0 $inuit-global-spacing-unit-tiny;
      padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big $inuit-global-spacing-unit-small;
    }
  }
}
