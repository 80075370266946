.c-university-structure {
  $self: &;

  position: relative;

  .c-candidate-inf & {
    padding: 0;
  }

  &__thumbnail {
    background-color: color_var(bg, 6);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 100%;
    width: 140px;
    height: 140px;
    margin: 0 auto $inuit-global-spacing-unit-big;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;

    &-img {
      display: none;
    }

    svg {
      width: 120px;
      height: auto;
      margin-top: $inuit-global-spacing-unit;
    }
  }

  &__item {
    height: 100%;
    padding-top: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-micro;
    display: block;
    position: relative;
    text-decoration: none;

    &-container {
      margin-top: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-big;

      &.splide__track {
        margin: 0 40px;
      }
    }
  }

  &__title {
    @include inuit-font-size($inuit-font-size-h4);
    min-height: $inuit-line-height-h4 * 2;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: color-var(font, 1);
  }

  &__position {
    @include inuit-font-size($inuit-font-size-h5);
    text-align: center;
    color: color-var(font, 1);
  }

  &__link {
    text-align: center;
    color: color-var(font, 5);
  }

  &.splide {
    @include mq($from: medium) {
      margin: 0 $inuit-global-spacing-unit-massive;
    }

    #{$self}__item {
      margin: 0 40px;
    }
  }

  .splide__list {
    height: auto;
    padding: 0 20%;
  }

  .splide__arrows {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
  }

  .splide__slide {
  }

  .splide__arrow {
    width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    line-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    border-radius: 50%;
    border: 0;
    opacity: 1;
    text-align: center;
    background-color: color-var(bg, 2);
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great
      rgba(0, 0, 0, 0.181149);
    cursor: pointer;
    pointer-events: all;
    z-index: 88;

    &:disabled {
      opacity: 0.3;
    }

    &--prev {
      left: 0;
      @include mq($from: medium) {
        left: - ($inuit-global-spacing-unit-big + $inuit-global-spacing-unit-huge);
      }
    }

    &--next {
      right: 0;
      @include mq($from: medium) {
        right: - ($inuit-global-spacing-unit-big + $inuit-global-spacing-unit-huge);
      }
    }

    svg {
      fill: color-var(bg, 1);
    }
  }
}
