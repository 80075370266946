/* ==========================================================================
   #article
   ========================================================================== */

.c-article {
  $self: &;
  display: block;
  position: relative;
  border-radius: $global-radius;
  box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  background: color-var(bg);
  text-decoration: none !important;
  @include inuit-font-size($inuit-font-size-h5);
  overflow: hidden;

  &__title {
    font-weight: $global-strong-font-weight !important;

    &--fixed-height {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      min-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-large;
    }
  }

  &:hover {
    color: inherit;
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $inuit-global-spacing-unit;
    position: absolute;
    top: $inuit-global-spacing-unit-tiny;
    left: $inuit-global-spacing-unit-tiny;

    &--bottom {
      top: auto;
      left: auto;
      bottom: 0;
      right: $inuit-global-spacing-unit;
    }
  }

  &__bottom-inf {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__date-public {
    color: color-var(font, 4);
    font-weight: $global-strong-font-weight;
    margin-top: $inuit-global-spacing-unit-big;
  }

  &__content {
    &--full-height {
      position: relative;
      height: 100%;
      padding-bottom: $inuit-global-spacing-unit-large + $inuit-global-spacing-unit-large;
    }
  }

  &__date {
    color: color-var(font, 2);
    padding: $inuit-global-spacing-unit-micro;
    border-radius: $global-radius;
    border: 1px solid color-var(bg, 6);
    background-color: color-var(bg, 5);
  }

  &__desc {
    background: color-var(bg);
    // padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit;
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-large;
  }

  &__excerpt {
    margin-bottom: 0;
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 59.26%;
    }

    &-elem {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &__indicator {
    text-align: right;
    color: color-var(font, 5);
    font-family: $global-heading-font-family;
    font-weight: $global-strong-font-weight;
    margin-top: $inuit-global-spacing-unit-big;

    &--arrow-left {
      text-align: left;
    }
  }

  &__calendary-icon {
    margin: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-micro 0 0;
  }

  &__cta {
    color: color-var(font, 5);
    font-family: $global-heading-font-family;
    font-size: ceil($inuit-global-font-size - 2);
    font-weight: $global-heading-font-weight;
    margin-top: $inuit-global-spacing-unit-large;
    text-align: right;

    .c-icon {
      margin-left: $inuit-global-spacing-unit-micro;
    }
  }

  &--horizontal,
  &--big {
    display: flex;
  }

  &--horizontal {

    #{$self}__desc,
    #{$self}__image {
      flex: 1;
    }

    #{$self}__image {
      max-width: 270px;
    }

    #{$self}__desc {
      padding: 0;
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &--big {
    #{$self}__desc {
      flex: 4;
      padding: $inuit-global-spacing-unit;
      @include inuit-font-size($inuit-font-size-h4);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      &:after {
        content: '';
        flex: 0 0 100%;
      }
    }

    #{$self}__meta,
    #{$self}__content {
      flex: 0 0 100%;
    }

    #{$self}__image {
      flex: 8;
      order: 2;
    }

    @include mq($from: medium) {
      #{$self}__meta {
        top: auto;
        left: auto;
        bottom: 0;
        right: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
      }
    }

    &-half {

      #{$self}__desc,
      #{$self}__image {
        flex-basis: 50%;
      }

      #{$self}__desc {
        padding: $inuit-global-spacing-unit-great;
      }
    }
  }

  &--promo {
    text-align: center;

    #{$self}__meta {
      justify-content: center;
    }
  }

  &--simple {
    background: transparent;
    @include inuit-font-size($inuit-font-size-h4);
    padding: $inuit-global-spacing-unit-tiny 0;
    border-bottom: $global-border solid color-var(border);

    #{$self}__title {
      margin-bottom: 0;
    }

    &:hover {
      #{$self}__title {
        color: color-var(font, 4);
      }
    }
  }

  &--side {
    
    @include mq($from: large) {
      display: flex;

      #{$self}__desc,
      #{$self}__image {
        flex: 1;
      }

      #{$self}__image {
        max-width: 175px;
      }

      #{$self}__desc {
        padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-great;
      }
    }
  }

  &--blur-icon {
    .c-article__image {
      filter: blur($inuit-global-spacing-unit-micro);

      &::before {
        padding-bottom: 67.26%;
      }
    }

    .c-article__title {
      color: color-var(font, 5);
    }

    .c-article__img-icon-wrap {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: color-var(bg, 11);
        opacity: 0.2;
        z-index: 2;
      }
    }

    .c-article__icon-page {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }

    .c-article__arrow {
      display: block;
      margin-top: $inuit-global-spacing-unit-great;

      svg {
        width: $inuit-global-spacing-unit-large;
        height: $inuit-global-spacing-unit-small;
      }

      /* stylelint-disable-next-line selector-max-compound-selectors */
      .is-sgtih & svg *,
      .is-pultusk & svg * {
        fill: color-var(font, 5);
      }
    }
  }

  &--large-content {
    @include mq($from: large) {
      #{$self}__image {
        max-height: 280px;
      }
    }
  }

  &--horizontal-mode {
    margin: 0;
  }

  &--horizontal-small {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .c-article__image {
      width: 34%;
    }

    .c-article__desc {
      flex-grow: 1;
      width: 100%;
    }

    .c-article__date {
      display: inline-flex;
    }
  }

  &--long-img {
    .c-article__image:before {
      padding-bottom: 87%;
    }
    .c-article__title {
      color: color-var(font,5);
    }
  }

  &--bottom-inf-fixed{
    .c-article__desc,.c-article__content{
      position: initial;
    }
    .c-article__bottom-inf{
      padding: 0 $inuit-global-spacing-unit-large $inuit-global-spacing-unit-big;
    }
  }

  @include mq($until: medium) {
    &--blur-icon {
      .c-article__image {
        filter: blur($inuit-global-spacing-unit-micro);

        &::before {
          padding-bottom: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-huge;
        }
      }
    }

    &__meta {
      &--bottom {
        top: $inuit-global-spacing-unit-tiny;
        left: $inuit-global-spacing-unit-tiny;
        bottom: auto;
        right: auto;
      }
    }

    &--horizontal-small {
      display: block;

      .c-article__image {
        width: 100%;
      }
    }

    &--big {
      flex-wrap: wrap;

      #{$self}__desc {
        order: 2;
        flex-basis: 100%;
      }

      #{$self}__image {
        order: 1;
        flex-basis: 100%;
      }
    }
  }
}
