.c-question {
  padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
  margin-bottom: $inuit-global-spacing-unit-large;
  border-radius: $global-radius;
  background-color: color-var(bg, 5);
  overflow: hidden;
  text-align: left;

  &__description {
    transition: height 0.3s linear;
    overflow: hidden;

    &:not(.is-active) {
      display: none;
    }
  }

  &__text {
    padding: $inuit-global-spacing-unit-small 0;
  }

  &__btn {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    color: color-var(font, 5);
  }

}
