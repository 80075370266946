.c-filter-form {

  &--group-margin-small {
    .c-label__container {
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }
  }

  .single-option-groups {
    display: block;

    &.is-hidden {
      display: none;
    }
  }

  .c-label:not(.c-radio) {
    font-weight: $global-strong-font-weight;
  }

  .c-label__description-bottom {
    max-width: 54%;
    margin: $inuit-global-spacing-unit-small auto 0 auto;
  }

  @include mq($until: medium) {
    .c-label__description-bottom {
      max-width: 100%;
    }
  }

  @include mq($until: small) {
    &.inline-block-mb {
      .c-radio--square-btns {
        display: inline-block;
      }
    }

    .c-radio--square-btns {
      display: block;
      text-align: center;

      .c-label__title {
        display: inline-block;
      }
    }
  }
}
