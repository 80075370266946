.c-card {
  background: color-var(func, 1);
  padding: $inuit-global-spacing-unit-large;
  border-radius: $global-radius;

  &--shadow {
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  }

  &--small {
    padding: $inuit-global-spacing-unit;
  }
}
