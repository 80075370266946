.c-autocomplete-list {
  text-align: left;
  list-style-type: none;
  border: $global-border solid color-var(border);
  border-top: 0;
  border-bottom-left-radius: $global-radius;
  border-bottom-right-radius: $global-radius;
  background: color-var(bg);
  box-shadow: $global-shadow;
  position: absolute;
  z-index: 100 !important;
  top: 100%;
  left: 0;
  overflow: auto;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;

  &__item {
    width: 100%;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
    cursor: pointer;
  }
}
