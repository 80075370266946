.c-not-found-page {
  position: relative;
  padding: $inuit-global-spacing-unit-massive 0 $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-massive;
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + #{$inuit-global-spacing-unit-massive});
    position: absolute;
    top: -$inuit-global-spacing-unit-massive;
    left: 0;
    background: url('../assets/images/svg/fala-blue.svg') no-repeat center;
  }

  &__title-main {
    font-size: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-huge;
  }

  &__wrapper-btn {
    margin-bottom: $inuit-global-spacing-unit-huge;
  }

  @include mq($until:medium) {
    &__title-main {
      font-size: $inuit-global-spacing-unit-massive;
    }
  }
}
