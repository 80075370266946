/* stylelint-disable property-no-vendor-prefix */
.c-vistula-blog {
  @include mq($until: medium) {
    overflow-x: hidden;
    padding-left: $inuit-global-spacing-unit-great;
  }

  &__title {
    margin: 0;

    @include mq($until: medium) {
      width: 100%;
      margin-left: -$inuit-global-spacing-unit-great;
      text-align: center;
    }
  }

  &__content {
    margin-top: $inuit-global-spacing-unit-huge;
    position: relative;
  }

  &__categories {
    padding: $inuit-global-spacing-unit-large;
    @include mq($from: medium) {
      min-height: 270px;
      height: calc(100% - 16px);
      margin-top: $inuit-global-spacing-unit;
    }

    .o-list-bare {
      margin-bottom: 0;
    }
  }

  &--grid {
    @include mq($from: medium) {
      display: grid;
      grid-template-columns: 7fr 5fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "first second"
        "first three";

      .c-article--large-content {
        height: 100%;
      }
    }

    .o-flex__item:first-child {
      grid-area: first;
    }

    .o-flex__item:nth-child(2) {
      grid-area: second;

      @include mq($from: medium) {
        .c-article {
          height: calc(100% - 16px);
        }
      }
    }

    .o-flex__item:nth-child(3) {
      grid-area: three;
    }

    @include mq($until: medium) {
      .o-flex__item:first-child, .o-flex__item.clone-item {
        .c-article__excerpt {
          height: $inuit-line-height-h5 * 4;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;/* autoprefixer: off */
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .o-flex__item {
    @include mq($until: medium) {
      padding-left: 0 !important;
    }
  }

  .c-slider__arrow:hover {
    background-color: color_var(bg, 1);

    * {
      fill: color_var(font, 5);
    }
  }
}
