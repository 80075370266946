.c-calendary-data {
  padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-big;
  margin-top: $inuit-global-spacing-unit-great;
  border-radius: $global-radius;

  &__date {
    margin-left: $inuit-global-spacing-unit-small;
  }

}
