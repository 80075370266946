/* ==========================================================================
   #RADIO BUTTON
   ========================================================================== */

.c-radio {
  @include clickableInput();

  &__replacement {
    border-radius: 50%;

    &:before {
      @include absolutePseudoContent();
      @include square-style($inuit-font-size-h5);
      border-radius: 50%;
      left: 3px;
      top: 3px;
    }
  }

  &--square-btns {
    padding: 0;

    .c-label__title {
      display: block;
      border: $global-border solid color-var(border, 1);
      text-align: center;
      padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-great;
      border-radius: $global-radius-input;
      box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
      background-color: color-var(bg, 1);
      color: color-var(font, 5);
      font-weight: 400;

      &:hover {
        box-shadow: inset 0 $inuit-global-spacing-unit-small $inuit-global-spacing-unit-micro rgba(0, 0, 0, 0.16);
        background-color: color-var(bg, 2);
        color: color-var(font, 3);
      }
    }

    input:checked {
      &[type='radio'] {
        &+.c-radio__replacement+.c-label__title {
          box-shadow: inset 0 $inuit-global-spacing-unit-small $inuit-global-spacing-unit-micro rgba(0, 0, 0, 0.16);
          background-color: color-var(bg, 2);
          color: color-var(font, 3);
        }
      }
    }

    .c-radio {
      &__replacement {
        display: none;
      }
    }

  }
}
