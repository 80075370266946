.c-shared-post {
  &__container {
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-great;
    border-radius: $global-radius;
    background: color-var(bg, 15);
  }

  @include mq($from: medium) {
    &__social-content {
      margin-left: auto;
    }
  }

  &__title {
    font-weight: $global-heading-font-weight;
    font-family: $global-heading-font-family;
  }

  &__social-link {
    margin-right: $inuit-global-spacing-unit-big;

    &:last-child {
      margin-right: 0;
    }
  }
}
