.c-content-tabs {
  padding: 0 0 $inuit-global-spacing-unit-huge;

  &--inner-content {
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__container {
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);

    &--navigation {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: $inuit-global-spacing-unit-massive;
      padding: 0 $inuit-global-spacing-unit-great 0 $inuit-global-spacing-unit-huge;
      margin-bottom: $inuit-global-spacing-unit-great;
      background-color: color-var(bg, 1);
      z-index: 10;
    }
  }

  &__btn {
    margin-left: auto;
  }

  @include mq($until: medium) {
    &__container {
      padding: 0;

      &--navigation {
        padding: 0 $inuit-global-spacing-unit-small;
      }
    }
  }
}
