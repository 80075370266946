.c-simple {
  position: relative;
  overflow: hidden;

  &--pt-small {
    padding-top: $inuit-global-spacing-unit-small;
  }

  &--pb-small {
    padding-bottom: $inuit-global-spacing-unit-small;
  }

  &--rm-shadow {
    .c-simple__image {
      box-shadow: none;
    }
  }


  &::before {
    content: '';
    background-image: url('../assets/images/svg/fala-blue.svg');
    background-position: right -140px top -10px;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @include mq($from: medium) {
      background-position: -300px -50px;
      background-size: initial;
    }
  }

  .is-sgtih &::before {
    background-image: url('../assets/images/svg/fala-sgtih.svg');
  }

  .is-pultusk &::before {
    background-image: url('../assets/images/svg/fala-pultusk.svg');
  }

  &--rm-wave {
    &::before {
      display: none;
    }
  }

  &__title {
    @include mq($until: medium) {
      text-align: center;
    }
  }

  &__image {
    display: block;
    box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small $inuit-global-spacing-unit-micro rgba(0, 0, 0, 0.4);

    &--margin {
      margin: 0 0 $inuit-global-spacing-unit auto;
      width: calc(100% - 32px);
    }

    &.wow {
      opacity: 0;
    }
  }

  .c-button {
    @include mq($until: medium) {
      width: 100%;
    }
  }

  @include mq($until: medium) {

    &__container {
      &--mobile-wrap {
        flex-wrap: wrap;
      }
    }

    &--img-mobile-full {
      overflow: hidden;

      .c-simple__image {

        img {
          width: calc(100% + #{$inuit-global-spacing-unit-huge});
          max-width: inherit;
          margin-left: (-$inuit-global-spacing-unit-big);
          margin-right: (-$inuit-global-spacing-unit-big);
        }
      }
    }
  }
}
