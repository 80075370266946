.c-single-inner-content {
  display: none;
  margin-top: $inuit-global-spacing-unit-great;
  box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  border-radius: $global-radius;

  &--disable-style {
    box-shadow: none;
    margin-top: 0;
    padding-top: 0;

    .c-single-inner-content__container {
      padding: 0;
    }
  }

  &--small-padding {
    .c-single-inner-content__container {
      padding: 0 $inuit-global-spacing-unit;
    }
  }

  &--disable-shadow{
    box-shadow: none;
  }

  &__container {
    padding: 0 $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit;
  }

  &.is-active {
    display: block;
  }

  @include mq($until: medium) {
    &__container {
      padding: 0 $inuit-global-spacing-unit-big;
    }

    &--small-padding {
      .c-single-inner-content__container {
        padding: 0;

        .c-simple-header--disable-style .c-simple-header__container {
          padding: 0 $inuit-global-spacing-unit;
        }
      }
    }
  }
}
