/* ==========================================================================
   #LABEL
   ========================================================================== */

.c-label {
  display: block;
  margin-bottom: $inuit-global-spacing-unit-great;

  &__title {
    @include inuit-font-size($inuit-font-size-h4);
    display: inline-block;
  }

  &__desc {
    @include inuit-font-size($inuit-font-size-h6);
    line-height: 1.5em;
    margin-top: $inuit-global-spacing-unit-micro;
    margin-bottom: $inuit-global-spacing-unit-micro;
    display: block;
  }

  &__error {
    display: block;
    @include inuit-font-size($inuit-font-size-h5);
    padding-left: $inuit-global-spacing-unit-great;
    margin-top: $inuit-global-spacing-unit-small;
    color: color-var(error);
    @include icon-inside(true, error);
  }

  &__asterisk {
    color: color-var(error);
  }

  &__slider-option {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  &__container {
    margin-bottom: $inuit-global-spacing-unit-great;

    &--item-inline {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: $inuit-global-spacing-unit-big;

      .c-hidden-input {
        display: none;
        margin-left: $inuit-global-spacing-unit-big;

        &.is-active {
          display: inline-block;
        }
      }
    }

    &.hidden-group {
      display: none;
    }

    &--fixed-width {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;

      &.c-label__container--title-middle,
      &.c-label__container--title-big {
        .c-label .c-label__title .c-label__title-main {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.c-label__container--title-middle {
        .c-label .c-label__title .c-label__title-main {
          min-height: 65px;
        }
      }

      &.c-label__container--title-big {
        .c-label .c-label__title .c-label__title-main {
          min-height: 91px;
        }
      }

      &.wider-btn {
        .c-label {
          width: 28%;

          &--title-group {
            width: 100%;
            max-width: 100%;
            display: block;
          }
        }
      }

      .c-label {
        display: flex;
        width: 23%;
        max-width: 234px;

        &--title-group {
          width: 100%;
          max-width: 100%;
          display: block;
        }

        .c-label__title {
          display: flex;
          padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large;
          width: 100%;
          align-items: center;
          justify-content: center;

          &--with-desc {
            flex-wrap: wrap;
            justify-content: center
          }

          .c-label__title-main {
            display: block;
            width: 100%;
            padding-bottom: $inuit-global-spacing-unit-small;
            border-bottom: $global-border solid color-var(border, 1);
          }

          .c-label__description-option {
            margin-top: $inuit-global-spacing-unit-small;
          }
        }
      }
    }
  }

  &--disabled {
    pointer-events: none;

    &.c-label,
    .c-label__title {
      color: color-var(font, 2);
    }
  }

  &--inline {
    display: inline-block;
    margin-right: $inuit-global-spacing-unit-small;
  }

  &--toggleable {
    padding-bottom: $inuit-global-spacing-unit-micro;
    border-bottom: 1px solid;
  }

  &.is-toggled {
    display: none;
  }

  &--toggleable-arr {
    width: 100%;
    padding-right: $inuit-global-spacing-unit-great;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: $inuit-global-spacing-unit-large;
      background: url('../assets/images/svg/chevron.svg') no-repeat center right;
    }

    &.is-active {
      &:after {
        transform: rotateX(180deg);
      }
    }
  }

  @include mq($until: medium) {

    &__container {
      &--item-inline {
        flex-wrap: wrap;

        .c-hidden-input {
          margin-left: none;

          &.is-active {
            display: block;
          }
        }
      }

      &--fixed-width {
        .c-label {
          width: 100%;
          max-width: 100%;
          margin-right: 0;

          .c-label__title {
            display: flex;
            width: 100%;
          }
        }

        .c-label__slider-option {
          flex-wrap: nowrap;
          width: 100%;
          padding: 0 $inuit-global-spacing-unit-huge;

          .c-label {
            margin-right: 0;
          }
        }
      }
    }
  }

  &--inline-flex {
    display: inline-flex;
    align-items: center;

    .c-label__title {
      margin-right: $inuit-global-spacing-unit;
    }
  }

  &--alternative-mobile {
    @include mq($until: medium) {
      text-decoration: underline;
      color: color-var(font, 5);
      display: inline-flex;
      position: relative;
      overflow: hidden;

      .c-select__title {
        display: none !important;
      }

      .c-label__title {
        margin: 0;
        padding-right: $inuit-global-spacing-unit-big;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: - $inuit-global-spacing-unit-tiny;
          width: $inuit-global-spacing-unit-great;
          height: 100%;
          background: url('../assets/images/svg/triangle.svg') no-repeat center right $inuit-global-spacing-unit;
        }
      }
    }
  }

  &--small {
    .c-select__title {
      height: $inuit-global-spacing-unit-huge;
      display: flex;
      align-items: center;
    }
  }

  &--language-switcher {
    margin: 0;
    display: flex;
    align-items: center;

    @include mq($until: medium) {
      margin-right: -$inuit-global-spacing-unit;
    }

    .c-select__title {
      color: color-var(font, 3);
      background: none;
      border: none;
      margin: 0;
      padding: 0 $inuit-global-spacing-unit-great 0 0;

      &::after {
        background: url('../assets/images/svg/triangle_white.svg') no-repeat center right 16px;
      }
    }

    .c-select__item {
      background-color: color_var(bg, 1);
      width: calc(100% + 32px);
      margin-left: -$inuit-global-spacing-unit-great;
      text-align: center;
    }
  }

  &--nowrap {
    .c-select__title {
      white-space: nowrap;
    }
  }
}
