.c-union-european {
  position: relative;
  padding: $inuit-global-spacing-unit-huge 0;
  box-shadow: inset 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-small rgba(0, 0, 0, 0.08);
  background-color: color-var(bg, 1);

  &__img-wrapper {
    text-align: right;
  }

  &__img {
    margin-right: $inuit-global-spacing-unit-great;

    &:last-child {
      margin-right: 0;
    }
  }

  @include mq($until: medium) {
    text-align: center;

    &__img-wrapper {
      text-align: center;
      margin-top: $inuit-global-spacing-unit-large;
    }
    &__img {
        margin: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
    }
  }
}
