.c-search-in-team {
  &__container {
    @include mq($from: medium) {
      padding: 0 $inuit-global-spacing-unit-big;
    }
  }

  &__form {
    width: 100%;
    box-shadow: $global-shadow;
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-big;
    @include mq($from: medium) {
      padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-huge;
    }
  }

  &__submit {
    min-width: 128px;
  }

  .c-label {
    width: 100%;
    margin-bottom: 0;
  }

  &__results {
    @include mq($until: medium) {
      margin: 0 $inuit-global-spacing-unit-big;
    }
  }

  &__item {
    width: 100%;
    box-shadow: $global-shadow;
    padding: $inuit-global-spacing-unit-great;
    margin-top: $inuit-global-spacing-unit-great;
    display: block;
    position: relative;

    &:hover {
      color: color_var(font, 1);
    }
  }

  &__name {
    color: color-var(font, 5);
    @include inuit-font-size($inuit-font-size-h4);
    font-weight: 700;
    text-decoration: underline;
    margin: 0;
  }

  &__description {
    margin-top: $inuit-global-spacing-unit-big;
  }

  &__terms {
    color: color-var(font, 2);
    @include inuit-font-size($inuit-font-size-h5);
    margin-top: $inuit-global-spacing-unit-great;
  }

  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
