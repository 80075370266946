.c-children-menu {
  position: sticky;
  top: 0;
  padding: $inuit-global-spacing-unit-large;
  margin-top: $inuit-global-spacing-unit-great;
  border-radius: $global-radius 0 0 $global-radius;
  border: $global-border solid color-var(border, 1);

  &--mobile {
    display: none;
  }

  &__title {
    font-family: $global-heading-font-family;
    font-weight: $global-heading-font-weight;
  }

  &__item {
    margin-bottom: $inuit-global-spacing-unit-micro;
  }

  &__link {
    display: inline-block;
    padding: $inuit-global-spacing-unit-tiny;
    border-radius: $global-radius;
    font-family: $global-heading-font-family;
    @include inuit-font-size($inuit-font-size-h5, $important: false);
    font-weight: $global-heading-font-weight;
    word-break: break-word;
    color: color-var(font, 5);
    transition: all 0.2s ease-out;
    text-decoration: none;

    &:hover,
    &.is-active {
      background-color: color-var(bg, 15);
      color: color-var(font, 1);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  @include mq($until: medium) {
    border: 0;
    padding: 0;

    &--desktop {
      display: none;
    }

    &--mobile {
      position: relative;
      display: flex;
      margin-top: $inuit-global-spacing-unit-tiny;

      .c-select {
        option:checked {
          display: none;
        }

        .active {
          background-color: color-var(main, 1);
          color: color-var(bg, 1);
        }
      }

      .c-select__title {
        box-shadow: none !important;
        color: color-var(font, 1);
        border-color: color-var(border, 1);
      }
    }
  }
}

.c-children-menu--mobile .c-select__title.is-shorter {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 74%;
  overflow: hidden;
}
@media (max-width: 47.99em) {
  .c-children-menu--desktop + .js-prev-section {
    display: none !important;
  }
  .js-header.is-fixed.is-active
    + .o-wrapper
    + .o-composer
    .js-candidate-inf-menu {
    top: 133px;
  }
}
