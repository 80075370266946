.c-tag {
  display: inline-block;
  color: color-var(font, 3);
  @include inuit-font-size($inuit-font-size-h6, 1.3334em);
  text-transform: uppercase;
  font-weight: 600;
  font-family: $global-heading-font-family;
  padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
  background: color-var(bg, 3);
  border-radius: 4px;

  &--alternative {
    color: color-var(font, 2);
    background: color-var(bg);
    border: 1px solid color-var(border);
    border-radius: 2px;
  }

  &--secondary {
    color: color-var(font, 3);
    background: color-var(bg, 13);
  }

  &--green {
    color: color-var(font, 3);
    background: color-var(bg, 16);
  }
}
