.c-see-more-link {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  font-size: ceil($inuit-global-font-size - 2);
  font-weight: $global-heading-font-weight;
  font-family: $global-heading-font-family;
  transition: $global-transition;
  color: color-var(font, 5);
  text-decoration: none;

  &--arrow-pseudo {
    position: relative;

    &::after {
      content: '';
      margin-left: $inuit-global-spacing-unit-tiny;
      display: inline-block;
      width: $inuit-global-spacing-unit;
      height: $inuit-global-spacing-unit-tiny;
      vertical-align: middle;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.01 3.00057H1C0.45 3.00057 0 3.45057 0 4.00057C0 4.55057 0.45 5.00057 1 5.00057H12.01V6.79057C12.01 7.24057 12.55 7.46057 12.86 7.14057L15.64 4.35057C15.83 4.15057 15.83 3.84057 15.64 3.64057L12.86 0.850567C12.55 0.530567 12.01 0.760566 12.01 1.20057V3.00057Z' fill='%23139EB8'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: $global-transition;
    }
  }

  &__icon * {
    fill: color-var(font, 5);
  }

  &--light {
    color: color-var(font, 3);

    .c-see-more-link {
      &__icon * {
        fill: color-var(font, 3);
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: color-var(font, 1);
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.01 3.00057H1C0.45 3.00057 0 3.45057 0 4.00057C0 4.55057 0.45 5.00057 1 5.00057H12.01V6.79057C12.01 7.24057 12.55 7.46057 12.86 7.14057L15.64 4.35057C15.83 4.15057 15.83 3.84057 15.64 3.64057L12.86 0.850567C12.55 0.530567 12.01 0.760566 12.01 1.20057V3.00057Z' fill='%23000000'/%3E%3C/svg%3E%0A");
    }
  }

  &:hover &__icon * {
    fill: color-var(font, 1);
  }

}
