.c-toggle-list {
  @include inuit-font-size($inuit-font-size-h4 + 2px);
  margin-bottom: $inuit-global-spacing-unit-huge;
  position: relative;

  &:after {
    @include absolutePseudoContent();
    right: 0;
    top: 10px;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 8px solid color-var(border, 2);
    transition: transform 0.3s ease-in-out;
  }

  &.is-toggled:after {
    transform: rotate(180deg);
  }

  &__title {
    border-bottom: 1px solid color-var(func, 4);
    padding-bottom: $inuit-global-spacing-unit-big;
    cursor: pointer;
  }

  &__content {
    display: none;
  }

  &.is-toggled &__content {
    display: block;
  }
}
