.c-cookies {
  max-width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: $inuit-global-spacing-unit-great;
  z-index: 1000;

  &__container {
    background-color: color-var(bg, 1);
    border-radius: $global-radius;
    box-shadow: $global-shadow;
    padding: $inuit-global-spacing-unit-big;
    @include mq($until: medium) {
      display: block;
    }
  }

  &__description {
    margin-bottom: $inuit-global-spacing-unit-small;
    padding-right: $inuit-global-spacing-unit-big;
    @include mq($from: medium) {
      max-width: 80%;
      margin-bottom: 0;
    }
  }
}
