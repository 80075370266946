.c-team {
  &__container {
    margin-bottom: $inuit-global-spacing-unit-big;
    @include mq($from: medium) {
      float: right;
      padding: 0 0 $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-huge;
      display: flex;
      flex-direction: column;
      width: 33.33%;
      margin-bottom: 0;
      z-index: 2;
    }
  }

  &__title {
    @include mq($from: medium) {
      text-align: left;
    }
  }

  &__icon {
    svg {
      max-width: 80%;
    }
  }
}
