.box-student {
  position: relative;
  padding: $inuit-global-spacing-unit-great;
  border-radius: $global-radius;
  filter: drop-shadow(0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.12));
  background-color: color-var(bg);
  max-width: 370px;

  &.wow {
    opacity: 0;
  }

  &__image {
    opacity: 0;

    &-container {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit;
      height: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit;
      border-radius: 50%;
      border: $inuit-global-spacing-unit-tiny - 2 solid color-var(bg);
      overflow: hidden;

      @include mq($from: medium) {
        left: 0;
      }
    }
  }

  &__name {
    margin-bottom: $inuit-global-spacing-unit-tiny;
  }

  &__major {
    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin: 0 $inuit-global-spacing-unit-micro;
      width: $inuit-global-spacing-unit-micro;
      height: $inuit-global-spacing-unit-micro;
      border-radius: 50%;
      background-color: color-var(font, 4);
    }
  }

  @include mq($until: medium) {
    padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big
      $inuit-global-spacing-unit-big $inuit-global-spacing-unit-large;
    max-width: 100%;
    width: 100%;
    filter: drop-shadow(0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-tiny rgba(0, 0, 0, 0.12));

    &__image {
      left: 50%;
    }

    &__major,
    &__name {
      display: block;
      text-align: center;

      &::before {
        display: none;
      }
    }

    &__link-wrapper {
      text-align: center;
    }
  }
}
