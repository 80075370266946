.c-simple {
  &__desc {
    margin-bottom: $inuit-global-spacing-unit-big;
    line-height: 1.5em;

    @include mq($until: medium) {
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }

    &--bolder {
      font-weight: 700;
    }

    &--small {
      @include inuit-font-size($inuit-font-size-h5);
    }

    &--trimmed p:last-of-type {
      @include mq($until: medium) {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-small;
  }

  &:not(.c-simple--alternative) {
    .c-simple__content--rm-p-left {
      padding-left: 0 !important;
    }
  }

  &__image {
    &--rm-p-left {
      padding-left: 0;
    }

    img {
      border-radius: 4px;
      margin: 0 auto;
      display: block;
    }

    &--round {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }
  }

  &--alternative {
    .c-simple__images-container {
      order: 1;

      &--rm-p-left {
        padding-left: 0;
      }
    }

    .c-simple__image {
      order: 1;

      &--rm-p-left {
        padding-left: 0;
      }
    }

    .c-simple__content {
      order: 2;
    }
  }

  &__btn-wrapper {
    order: 3;

    .c-button {
      margin-right: $inuit-global-spacing-unit-factor-tiny;
    }
  }

  @include mq($until: medium) {
    &--title-mobile-left {
      .c-simple__title {
        text-align: left;
      }
    }

    &__content {
      order: 2;

      p {
        margin-bottom: $inuit-global-spacing-unit-small;
      }
    }

    &__image {
      order: 1;
      margin-bottom: $inuit-global-spacing-unit-big;

      &--round {
        text-align: center;
        width: 140px;
        height: 140px;
        margin: 0 auto;

        img {
          object-fit: cover;
        }
      }
    }

    &__btn-wrapper {
      .c-button {
        margin-right: 0;
      }
    }

    &--alternative {
      .c-simple__image {
        &--rm-p-left {
          padding-left: 0;
        }
      }
    }

    &__content,
    &__image {
      padding-left: 0 !important;
    }
  }

  &--seo {
    @include mq($until: medium) {
      .c-simple__images-container {
        display: none;
      }
    }

    @include mq($from: large) {
      overflow: visible;

      &::before {
        background: color-var(bg, 2);
        opacity: 0.15;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
