.c-event-list-information {
  &__item {
    position: relative;
    padding-left: $inuit-global-spacing-unit-great;
    margin-bottom: $inuit-global-spacing-unit-big;
    line-height: $inuit-global-spacing-unit-big;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      display: none;
    }
  }

  &__title,
  &__text,
  &__icon-wrapper,
  &__icon-wrapper svg {
    display: inline-block;
    vertical-align: middle;
  }

  &__icon-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  @include mq($until: medium) {
    &__text {
      display: block;
    }
  }
}