.c-pagination {
  margin-top: $inuit-global-spacing-unit-huge + ($inuit-global-spacing-unit-huge / 2);

  &__content {
    @include mq($until: medium) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @include mq($until: medium) {
    &__buttons {
      order: 1;
    }

    &__prev {
      order: 2;
      max-width: 50%;
    }

    &__next {
      order: 3;
      max-width: 50%;
      margin-left: auto;
    }
  }

  &--vue {
    @include mq($until: medium) {
      padding: 0 $inuit-global-spacing-unit-big;
    }
  }
}
