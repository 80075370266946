.c-course-specialization {
  $self: &;
  padding: $inuit-global-spacing-unit;
  border-radius: $global-radius;
  background-color: color-var(bg, 5);

  &--vertical-position {
    text-align: left;
    padding-bottom: 0;

    #{$self}__title {
      margin-bottom: 0;
      font-size: $inuit-global-font-size - 2px;
    }

    #{$self}__item {
      &--block {
        display: inline-block;
        margin-bottom: 0;
      }
    }

    #{$self}__data {
      margin-bottom: $inuit-global-spacing-unit;
    }
  }

  &__item {
    &--block {
      display: block;
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }
  }
}
