.c-accordion {
  &--small-padding {
    padding: 0 16px;
  }

  &--rm-padding {
    padding: 0;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__item {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
    margin-bottom: $inuit-global-spacing-unit-great;
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    background-color: color-var(bg, 1);
    overflow: hidden;
  }

  &__btn {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    outline: none;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &.active {
      .c-accordion {
        &__icon-arrow {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
  }

  &__icon-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all 0.3s linear;

    /* stylelint-disable-next-line selector-max-compound-selectors */
    .is-sgtih & svg *,
    .is-pultusk & svg * {
      fill: color-var(font, 5);
    }
  }

  &__icon-wrapper,
  &__btn-text {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0 !important;
  }

  &__icon-wrapper {
    width: $inuit-global-spacing-unit-great;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__btn-text {
    margin-left: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;
    padding-right: $inuit-global-spacing-unit-small;
  }

  &__description {
    transition: height 0.3s linear;
    overflow: hidden;

    &:not(.is-active) {
      display: none;
    }
  }

  &__text {
    margin-top: $inuit-global-spacing-unit-great;
    padding-top: $inuit-global-spacing-unit-great;
    border-top: 1px solid color-var(border, 2);

    @include mq($until: medium) {
      margin-top: $inuit-global-spacing-unit-big;
      padding-top: $inuit-global-spacing-unit-big;

      p {
        margin-bottom: $inuit-global-spacing-unit-small;
      }

      ul {
        margin-bottom: $inuit-global-spacing-unit-small;
      }
    }

    ul {
      padding-left: $inuit-global-spacing-unit-micro;
    }
  }

  @include mq($until: medium) {
    &__item {
      padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-small;
      margin-bottom: $inuit-global-spacing-unit-small;
    }

    &__btn-text {
      padding-right: $inuit-global-spacing-unit-tiny;
    }
  }

}
