$baseColors: ( 
                        "main": ( 
                                    #01819B
                 
            ),
                                "sub": ( 
                                    #083A58
                                    #027188
                                    #062B41
                                    #041C2A
                                    #F9F6CC
                                    #E6F3F5
                                    #008544
                                    #01819B
                 
            ),
                                "sub2": ( 
                                    #2C0140
                                    #270039
                                    #100017
                                    #100017
                                    #3C0157
                                    #78210E
                                    #e9e4ec
                 
            ),
                                "sub3": ( 
                                    #01723B
                                    #00743C
                                    #00351B
                                    #00361C
                                    #008545
                                    #FE5F00
                                    #e5f3ec
                 
            ),
                                "func": ( 
                                    #ffffff
                                    #F4F4F4
                                    #E5E5E5
                                    #C4C4C4
                                    #757575
                                    #545454
                                    #000000
                 
            ),
                                "success": ( 
                                    #2eaa61
                 
            ),
                                "error": ( 
                                    #CA383A
                 
            ),
            	
)!default;
$useColors: ( 
                        "font": ( 
                                    color-var(func,7)
                                    color-var(func,6)
                                    color-var(func,1)
                                    color-var(func,5)
                                    color-var(main,1)
                                    color-var(sub,1)
                                    color-var(success,1)
                                    color-var(error,1)
                            ), 
                                "bg": ( 
                                    color-var(func,1)
                                    color-var(main,1)
                                    color-var(sub,1)
                                    color-var(func,1)
                                    color-var(func,2)
                                    color-var(func,3)
                                    color-var(sub,2)
                                    color-var(sub,3)
                                    color-var(sub,4)
                                    color-var(func,4)
                                    color-var(func,7)
                                    color-var(success,1)
                                    color-var(error,1)
                                    color-var(sub,5)
                                    color-var(sub,6)
                                    color-var(sub,7)
                            ), 
                                "border": ( 
                                    color-var(func,3)
                                    color-var(main,1)
                                    color-var(sub,1)
                                    color-var(sub,2)
                                    color-var(success,1)
                                    color-var(error,1)
                            ), 
            	
)!default;
	 		
:root{
                                                                    --color-main-1: #01819B;
                     
                                                                            --color-sub-1: #083A58;
                                            --color-sub-2: #027188;
                                            --color-sub-3: #062B41;
                                            --color-sub-4: #041C2A;
                                            --color-sub-5: #F9F6CC;
                                            --color-sub-6: #E6F3F5;
                                            --color-sub-7: #008544;
                                            --color-sub-8: #01819B;
                     
                                                                            --color-sub2-1: #2C0140;
                                            --color-sub2-2: #270039;
                                            --color-sub2-3: #100017;
                                            --color-sub2-4: #100017;
                                            --color-sub2-5: #3C0157;
                                            --color-sub2-6: #78210E;
                                            --color-sub2-7: #e9e4ec;
                     
                                                                            --color-sub3-1: #01723B;
                                            --color-sub3-2: #00743C;
                                            --color-sub3-3: #00351B;
                                            --color-sub3-4: #00361C;
                                            --color-sub3-5: #008545;
                                            --color-sub3-6: #FE5F00;
                                            --color-sub3-7: #e5f3ec;
                     
                                                                            --color-func-1: #ffffff;
                                            --color-func-2: #F4F4F4;
                                            --color-func-3: #E5E5E5;
                                            --color-func-4: #C4C4C4;
                                            --color-func-5: #757575;
                                            --color-func-6: #545454;
                                            --color-func-7: #000000;
                     
                                                                            --color-success-1: #2eaa61;
                     
                                                                            --color-error-1: #CA383A;
                     
                    	
                                                                        --color-font-1: var(--color-func-7);
                                            --color-font-2: var(--color-func-6);
                                            --color-font-3: var(--color-func-1);
                                            --color-font-4: var(--color-func-5);
                                            --color-font-5: var(--color-main-1);
                                            --color-font-6: var(--color-sub-1);
                                            --color-font-7: var(--color-success-1);
                                            --color-font-8: var(--color-error-1);
                                                                                                --color-bg-1: var(--color-func-1);
                                            --color-bg-2: var(--color-main-1);
                                            --color-bg-3: var(--color-sub-1);
                                            --color-bg-4: var(--color-func-1);
                                            --color-bg-5: var(--color-func-2);
                                            --color-bg-6: var(--color-func-3);
                                            --color-bg-7: var(--color-sub-2);
                                            --color-bg-8: var(--color-sub-3);
                                            --color-bg-9: var(--color-sub-4);
                                            --color-bg-10: var(--color-func-4);
                                            --color-bg-11: var(--color-func-7);
                                            --color-bg-12: var(--color-success-1);
                                            --color-bg-13: var(--color-error-1);
                                            --color-bg-14: var(--color-sub-5);
                                            --color-bg-15: var(--color-sub-6);
                                            --color-bg-16: var(--color-sub-7);
                                                                                                --color-border-1: var(--color-func-3);
                                            --color-border-2: var(--color-main-1);
                                            --color-border-3: var(--color-sub-1);
                                            --color-border-4: var(--color-sub-2);
                                            --color-border-5: var(--color-success-1);
                                            --color-border-6: var(--color-error-1);
                                        	
    	
    --color-primary-button: var(--color-main-1);		
}

html.is-pultusk{
    --color-main-1: var(--color-sub2-5);
    --color-sub-1: var(--color-sub2-1);
    --color-sub-2: var(--color-sub2-2);
    --color-sub-3: var(--color-sub2-3);
    --color-sub-4: var(--color-sub2-4);
    --color-primary-button: var(--color-sub2-6);
}
html.is-sgtih{
    --color-main-1: var(--color-sub3-5);
    --color-sub-1: var(--color-sub3-1);
    --color-sub-2: var(--color-sub3-2);
    --color-sub-3: var(--color-sub3-3);
    --color-sub-4: var(--color-sub3-4);
    --color-primary-button: var(--color-sub3-6);
}