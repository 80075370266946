/* stylelint-disable max-nesting-depth */
.c-post-list {
  overflow-x: hidden;

  &__title {
    margin-bottom: 0;
  }

  &__content {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &__cta {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &--reversed {
    margin-top: -$inuit-global-spacing-unit-huge * 6;
  }

  &--bordered {
    .c-tease,
    .c-article {
      box-shadow: $global-shadow;
      background: color-var(bg);
      color: color-var(font) !important;

      &.c-tease,
      &.c-article,
      &.c-tease *,
      &.c-article * {
        color: color-var(font) !important;
      }

      .c-tag {
        color: color-var(font, 3) !important;

        &--alternative {
          color: color-var(font, 2) !important;
        }
      }
    }
  }

  &--spacing-top-none {
    padding-top: 0;

    .c-post-list__content {
      margin-top: 0;
    }
  }

  &__container {
    &.is-expand {
      .c-post-list__content {
        display: block !important;
      }
    }
  }

  &__item {
    position: relative;
    margin-top: $inuit-global-spacing-unit-great;

    &--month-sort {
      margin-bottom: $inuit-global-spacing-unit-huge;
    }

    &-month-title {
      position: absolute;
      top: -$inuit-global-spacing-unit-huge;
      left: $inuit-global-spacing-unit-great;
      text-transform: capitalize;
    }

    .c-article {
      height: 100%;
    }

    &--hide-img {
      .c-tease__image {
        display: none;
      }
    }
  }

  &--promotion {
    .o-flex--grids {
      flex-wrap: nowrap;

      .o-flex__item:nth-child(4) {
        opacity: 0.2;
      }
    }
  }

  &--inmedia {
    .c-post-list__title {
      text-align: left !important;
    }
  }

  &--publication {
    &.c-post-list--horizontal-mode {
      padding: 0 0 $inuit-global-spacing-unit-big 0;

      .c-post-list__content {
        margin-top: 0;
      }

      .c-post-list__item {
        margin-top: 0;
      }

      @include mq($from: medium) {
        .c-article {
          display: flex;
          flex-direction: row-reverse;
        }

        .c-article__title {
          @include inuit-font-size($inuit-font-size-h1);
          font-weight: 500 !important;
        }

        .c-article__image {
          width: 50%;
        }

        .c-article__desc {
          width: 50%;
        }

        .c-article__meta {
          top: auto;
          left: auto;
          right: $inuit-global-spacing-unit;
          bottom: 0;
        }
      }
    }
  }

  @include mq($until: medium) {
    &__item {
      &--month-sort {
        margin-bottom: 0;
      }

      &-month-title {
        display: none;
      }
    }

    &--slider-mobile {
      .c-slider--mobile {
        margin-left: 0;
        flex-wrap: nowrap;

        .c-post-list__month-date {
          display: none;
        }

        .c-slider__item {
          padding-left: 0;
          width: 100%;
          background-color: color-var(bg, 1);
        }

        .c-slider__item--without-background {
          background-color: transparent;
        }
      }
    }
  }
}
