/* ==========================================================================
   #tease
   ========================================================================== */

.c-tease {
  $self: &;
  display: block;
  position: relative;
  text-decoration: none !important;
  @include inuit-font-size($inuit-font-size-h5);
  height: 100%;

  .o-link {
    text-decoration: none !important;
  }

  &:hover {
    color: inherit;
  }

  &__title {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__desc {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
  }

  &__content {
    @include inuit-font-size($inuit-font-size-h4);
  }

  &__excerpt {
    margin-bottom: 0;
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: relative;
    display: block;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 59.26%;
    }

    &-elem {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &--horizontal,
  &--big {
    display: flex;
  }

  &--horizontal {

    #{$self}__desc,
    #{$self}__image {
      flex: 1;
    }

    #{$self}__image {
      max-width: 270px;
    }

    #{$self}__desc {
      padding: 0;
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &--big {
    #{$self}__desc {
      flex: 7;
      padding: $inuit-global-spacing-unit;
      @include inuit-font-size($inuit-font-size-h4);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      &:after {
        content: '';
        flex: 0 0 100%;
      }
    }

    #{$self}__meta,
    #{$self}__content {
      flex: 0 0 100%;
    }

    #{$self}__image {
      flex: 5;
      order: 2;

      &:before {
        padding-bottom: 100%;
      }
    }
  }

  &--promo {
    text-align: center;

    #{$self}__meta {
      justify-content: center;
    }
  }

  &--simple {
    background: transparent;
    @include inuit-font-size($inuit-font-size-h4);
    padding: $inuit-global-spacing-unit-tiny 0;
    border-bottom: $global-border solid color-var(border);

    #{$self}__title {
      margin-bottom: 0;
    }

    &:hover {
      #{$self}__title {
        color: color-var(font, 4);
      }
    }
  }

  &--team {
    padding: $inuit-global-spacing-unit-huge 0;
  }

  &--team .c-tease {
    &__image {
      width: 230px;
      max-width: 100%;
      margin: auto;
      border-radius: 50%;

      &:before {
        padding-bottom: 100%;
      }
    }

    &__desc {
      text-align: center;
    }

    &__additional-info {
      margin-top: $inuit-global-spacing-unit-big;
    }

    &__phone,
    &__email {
      display: block;
      text-align: center;
    }
  }

  &--promotion {
    height: 100%;

    .c-tease__image:before {
      padding-bottom: 100%;
    }

    .c-tease__desc {
      padding-left: 0;
      padding-right: 0;
      position: static;
      padding-bottom: $inuit-global-spacing-unit-huge;
    }

    .c-article__excerpt {
      min-height: 5em;
    }

    .c-tease__title {
      text-align: left;
      font-weight: 600;
    }

    .c-tease__content {
      padding-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
      position: static;
    }

    .c-button {
      position: absolute;
      left: 0;
      bottom: $inuit-global-spacing-unit-huge;

      .is-pultusk & {
        background-color: color-var(sub2, 6);
        border-color: color-var(sub2, 6);
      }
    }
  }

  &--promotion-second,
  &--shadow-simple {
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    background: color-var(bg);
    overflow: hidden;

    .c-tease__title {
      text-align: left;
    }
  }

  &--inmedia {
    background: color-var(bg);
    text-align: center;
    padding-top: $inuit-global-spacing-unit-huge;
    color: color-var(font, 2);

    .c-article__excerpt {
      min-height: 10em;
    }

    .c-tease__title {
      min-height: 3.2em;
    }

    .c-tease__img {
      max-height: 80px;
      height: 80px;
    }
  }

  &--intranet {
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-great;
    border-radius: $global-radius;
    border: $global-border solid color-var(border, 1);
    text-align: center;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: color-var(bg, 15);
    }

    #{$self}__wrapper-title {
      margin: $inuit-global-spacing-unit-large 0;
      min-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-large;
      line-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-large;
    }

    #{$self}__title {
      margin: 0;
      display: inline-block;
      vertical-align: middle;
      color: color-var(font, 5);
    }

    #{$self}__desc {
      padding: 0;
    }

    #{$self}__img {
      height: $inuit-global-spacing-unit-great;
      width: auto;
    }
  }

  &--webinars {
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    overflow: hidden;

    .c-tease__title {
      text-align: left;
    }

    .c-tease__btn-save {
      margin-right: $inuit-global-spacing-unit-tiny;
    }

    .c-tease__footer-card {
      margin-top: $inuit-global-spacing-unit-big;
    }
  }

  @include mq($until:medium) {
    &--webinars {
      .c-tease__image {
        display: none;
      }

      .c-tease__footer-card {
        text-align: center;
      }

      .c-tease__btn-save {
        width: 100%;
        margin-bottom: $inuit-global-spacing-unit-small;
      }
    }
  }

  &--nolink {
    pointer-events: none;
  }
}
