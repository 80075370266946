.c-box-icon {
  position: relative;
  margin-bottom: $inuit-global-spacing-unit-great;

  &__wrapper {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large $inuit-global-spacing-unit-massive;
    border-radius: $global-radius;
    background-color: color-var(bg, 15);
  }

  &--double-column {
    width: 50%;
    float: left;
    padding-right: 16px;

    &-right {
      float: right;
      padding-left: 16px;
    }

    &-clear {
      clear: both;
    }
  }

  &__icon {
    position: absolute;
    left: $inuit-global-spacing-unit-large;
    top: $inuit-global-spacing-unit-large;
    width: $inuit-global-spacing-unit-great;
    height: auto;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-huge;
  }

  &__text {
    padding-left: $inuit-global-spacing-unit;
  }

  @include mq($until: medium) {
    &--double-column {
      width: 100%;
      float: none;
      padding: 0;
    }

    &__icon {
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: $inuit-global-spacing-unit-large;
    }
    &__wrapper {
      padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-small;

    }
  }
}
