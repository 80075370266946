.c-intranet {
  $self: &;
  position: relative;
  padding-bottom: $inuit-global-spacing-unit-huge;

  &--single {
    #{$self}__header {
      padding: $inuit-global-spacing-unit-big 0 $inuit-global-spacing-unit-great;
      text-align: center;
    }

    #{$self}__img-main {
      margin-bottom: $inuit-global-spacing-unit-big;
    }

    #{$self}__wrapper {
      max-width: 970px;
      margin: 0 auto;
    }

  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + #{$inuit-global-spacing-unit-massive});
    position: absolute;
    top: -$inuit-global-spacing-unit-massive;
    left: 0;
    background: url('../assets/images/svg/fala-blue.svg') no-repeat left top;
    z-index: -1;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-factor;
    text-align: center;
  }

  &__wrapper {
    padding: $inuit-global-spacing-unit-big 0 0;
    box-shadow: 0 0 $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    background-color: color-var(bg, 1);
    border-radius: $global-radius;
  }

  &__boxes {
    display: none;
    padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
    box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);

    &.is-active {
      display: flex;
    }
  }

  &__box-wrap {
    width: 100%;
    margin-bottom: $inuit-global-spacing-unit-big;
    padding: 0 $inuit-global-spacing-unit-small;
  }

  &__category-form {
    text-align: center;
  }

  @include mq($until: medium) {
    &--single {
      margin-bottom: -$inuit-global-spacing-unit-big;
      padding-bottom: 0;

      #{$self}__wrapper {
        padding-top: 0;
      }
    }

    &__container {
      &--single {
        padding: 0;
      }
    }

    &::after {
      display: none;
    }

    &__container {
      padding: 0;
    }

    &__box-wrap {
      padding: 0;
    }

  }

}
