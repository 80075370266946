.c-wcag {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 109;
  @include mq($until: large) {
    top: auto;
    bottom: -1px;
    left: 0;
    right: 0;
    flex-direction: row;
  }
  
  &__element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.25rem 0 0 0.25rem;
    border: 1px solid var(--color-border-1);
    background-color: var(--color-bg-2);
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    &:hover {
      opacity: 0.9;
    }
    
    * {
      pointer-events: none;
    }

    @include mq($until: large) {
      border-radius: 0.25rem 0.25rem 0 0;
    }
  }
  
  svg {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  

}

html.wcag-font-size {
  &--15 {
    font-size: 17.25px;
  }
  &--20 {
    font-size: 18.5px;
  }
}

html.wcag-contrast {
  --color-success-1: black;
  --color-error-1: black;
  --color-font-1: white;
  --color-font-2: white;
  --color-font-3: white;
  --color-font-4: black;
  --color-font-5: black;
  --color-font-6: black;
  --color-font-7: black;
  --color-font-8: black;
  --color-bg-1: black !important;
  --color-bg-2: black;
  --color-bg-3: black;
  --color-bg-4: black;
  --color-bg-5: black;
  --color-bg-6: black;
  --color-bg-7: black;
  --color-bg-8: black;
  --color-bg-9: black;
  --color-bg-10: black;
  --color-bg-11: black;
  --color-bg-12: black;
  --color-bg-13: black;
  --color-bg-14: black;
  --color-bg-15: black;
  --color-bg-16: black;
  --color-border-1: yellow;
  --color-border-2: yellow;
  --color-border-3: yellow;
  --color-border-4: yellow;
  --color-border-5: yellow;
  --color-border-6: yellow;
  --color-primary-button: yellow;

  // Display
  *:not(.c-icon)::before,
  *:not(.c-icon)::after 
  {
    // display: none !important;
  }

  // Backgrounds
  // section
  // {
  //   transition: background-image 300ms ease;
  //   background-image: unset !important;
  // }
  
  section,
  .c-article,
  .c-multisite-popup__wrapper,
  .c-heading,
  .c-heading__subtitle,
  .c-hero--secondary .c-hero__subtitle.c-hero__subtitle,
  .c-slider-simple__item,
  .c-slider-simple__item a,
  .c-article__excerpt,
  .c-article .c-article__desc,
  .box-student,
  .c-button,
  .otgs-development-site-front-end,
  .c-school-inf-popup
  {
    transition: background-color 300ms ease, background 300ms ease;
    background-color: black !important;
    // background: black !important;
  }

  .c-union-european,
  .c-major-lists__item--gray.o-link--main-color,
  .c-slider__arrow,
  .splide__arrow
  {
    transition: all 300ms ease;
    background-color: yellow !important;
    background: yellow !important;
  }
  
  // Borders
  .c-multisite-popup__wrapper,
  .c-article,
  .box-student,
  .box-student__image-container,
  .c-button,
  .c-faq__item
  {
    transition: border 300ms ease;
    border: 1px solid yellow !important;
    border-color: yellow !important;
  }

  // Colors
  .c-union-european .u-font-color--2,
  .c-major-lists__item--gray.o-link--main-color
  {
    transition: color 300ms ease;
    color: black !important;
  }
  
  section,
  .c-heading,
  .c-heading__subtitle,
  .c-hero--secondary .c-hero__subtitle.c-hero__subtitle,
  .c-slider-simple__item,
  .c-slider-simple__item a,
  .c-article__excerpt,
  .u-font-color--2,
  .c-article .c-article__desc,
  .box-student,
  .c-button__text,
  .c-label__title,
  .c-faq__title
  {
    transition: color 300ms ease;
    color: white !important;
  }
  
  a,
  .c-button,
  .c-button svg,
  .c-button path,
  .c-button span.c-icon,
  .c-article__indicator,
  .c-article__indicator svg path
  {
    transition: fill 300ms ease, color 300ms ease;
    fill: yellow !important;
    color: yellow !important;
  }

  .c-slider__arrow,
  .splide__arrow
  {
    transition: fill 300ms ease, color 300ms ease;
    fill: black !important;
    color: black !important;
  }
  
  // Font weight
  .c-union-european .u-font-color--2
  {
    font-weight: 700 !important;
  }

  .c-multisite-popup__btn {
    padding: 10px !important;
  }

  .c-slider__arrow {
    top: 0;
  }
}