.c-simple-header {

  &--add-mr-bottom {
    margin-bottom: $inuit-global-spacing-unit-huge;

    @include mq($until: medium) {
      margin-bottom: $inuit-global-spacing-unit-big;
    }
  }

  &--add-mr-top {
    margin-top: $inuit-global-spacing-unit-huge;
  }

  &--disable-style {
    padding: 0;

    .c-simple-header__container {
      padding: 0;
    }
  }

  &__desc {
    margin-top: $inuit-global-spacing-unit-large;
  }

  &__inner-content {
    padding: $inuit-global-spacing-unit-small 0 0;
    border-radius: $global-radius;
  }

  &__title {
    padding-bottom: $inuit-global-spacing-unit-small;
    margin-bottom: 0;
  }

  @include mq($until: medium) {
    padding-bottom: $inuit-global-spacing-unit-big;

    &--disable-style {
      padding-bottom: 0;
    }

    .c-heading,
    &__title {
      font-size: $inuit-font-size-h2;
    }
  }
}
