.c-list-choice {
  list-style-type: none;
  @include inuit-font-size($inuit-font-size-h4);
  font-weight: 500;
  margin: 0;
  cursor: pointer;

  &__item {
    background-color: color_var(bg, 1);
    border-radius: $global-radius;
    border: 1px solid color-var(func, 4);
    padding: $inuit-global-spacing-unit-tiny;
    margin-right: $inuit-global-spacing-unit-small;
    display: flex;
    align-items: center;
  }

  &__remove {
    background: url('../assets/images/svg/highlight_off.svg') no-repeat center center;
    width: 40px;
    height: 40px;
    margin-left: $inuit-global-spacing-unit-tiny;
  }

  &--bg {
    background-color: color-var(bg, 5);
    margin-top: $inuit-global-spacing-unit-huge;
    margin-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-large;

    &::before {
      background-color: color-var(bg, 5);
      @include absolutePseudoContent();
      width: calc(100% + 24px);
      height: calc(100% + 24px);
      left: -$inuit-global-spacing-unit-small;
      top: -$inuit-global-spacing-unit-small;

      @include mq($from: large) {
        width: calc(100% + 48px);
        height: calc(100% + 48px);
        left: -$inuit-global-spacing-unit-large;
        top: -$inuit-global-spacing-unit-large;
      }
    }
  }
}
