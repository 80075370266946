.c-widget {
  &__title {
    font-family: $global-heading-font-family;
    @include inuit-font-size($inuit-global-font-size);
    font-weight: $global-heading-font-weight;
    margin-bottom: $inuit-global-spacing-unit-big
  }
  &__article { 
    margin-bottom: $inuit-global-spacing-unit-great;
  }
}
