.c-slider {
  position: relative;

  &__pager {
    text-align: center;
    margin-top: $inuit-global-spacing-unit-great;

    &--mobile,
    &--hidden {
      display: none;
    }
  }

  &__page {
    margin: $inuit-global-spacing-unit-micro;
    display: inline-block;
    @include square-style(15px);
    border-radius: 50%;
    background: color-var(bg, 2);
    cursor: pointer;
    opacity: 0.2;

    &--oval-shape {
      width: $inuit-global-spacing-unit-huge;
      height: $inuit-global-spacing-unit-tiny - 2;
      border-radius: $global-radius;
    }

    &:hover {
      opacity: 0.3;
    }

    &.is-active {
      opacity: 1;
    }
  }

  &--sliding-slide {
    display: flex;

    .c-slider {
      &__item {
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        opacity: 0;
        pointer-events: none;

        &:not(:first-child) {
          margin-left: -100%;
        }

        &.is-active {
          pointer-events: all;
          opacity: 1;
          transition: all 0.2s ease-out;

          &.right {
            @include anShowLeft(-100%, 0, 0.4s, linear);
          }

          &.left {
            @include anShowRight(100%, 0, 0.4s, linear);
          }
        }
      }
    }
  }

  &--fade {
    display: flex;

    .c-slider {
      &__item {
        @include transition();
        flex: 0 0 100%;
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-left: -100%;
        }

        opacity: 0;
        pointer-events: none;

        &.is-active {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }

  &--mobile {
    .c-slider {
      &__item {
        &.clone-item {
          display: none;
        }
      }
    }
  }

  &--arrow {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit $inuit-global-spacing-unit-huge;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    line-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    border-radius: 50%;
    border: 0;
    text-align: center;
    background-color: color-var(bg, 1);
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.181149);
    cursor: pointer;
    pointer-events: all;
    z-index: 88;

    svg {
      display: inline-block;
      vertical-align: middle;

      & * {
        fill: color-var(1);
      }
    }

    &--prev {
      left: $inuit-global-spacing-unit-great;
      transform: translateY(-50%) rotate(90deg);
    }

    &--next {
      right: $inuit-global-spacing-unit-great;
      transform: translateY(-50%) rotate(-90deg);
    }

    &:hover {
      svg * {
        fill: color-var(font, 5);
      }
    }
  }

  @include mq($until: 'medium') {
    &--arrow {
      padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
    }

    &__arrow {
      width: $inuit-global-spacing-unit-huge;
      height: $inuit-global-spacing-unit-huge;
      line-height: $inuit-global-spacing-unit-huge;
      display: flex;
      justify-content: center;
      align-items: center;

      &--mobile-top {
        top: $inuit-global-spacing-unit-huge;
      }

      &--top {
        top: 20%;
      }

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }

      &--prev-margin {
        left: - $inuit-global-spacing-unit-tiny;
      }
    }

    &--mobile {
      justify-content: flex-start;
      align-items: stretch;

      .c-slider {
        &__item {
          position: relative;
          flex: 0 0 85%;
          display: flex;
          align-items: stretch;
          opacity: 0;
          pointer-events: none;
          z-index: 3;

          &--centered {
            flex: 0 0 95%;
          }

          &.is-hide {
            opacity: 1;
            @include anHideLeft(0, -100%, 0.46s, linear);
          }

          &:not(:first-child) {
            margin-left: -85%;
            &.c-slider__item--centered{
              margin-left: -95%;
            }
          }

          &.is-active {
            pointer-events: all;
            opacity: 1;
            z-index: 6;
            @include anShowRight(100%, 0, 0.5s, linear);

            &+.c-slider__item {
              opacity: 1;
              transform: translateX(107%);
              z-index: 4;
            }
          }

          &.clone-item {
            display: flex;
          }
        }
      }
    }

    &__pager {
      &--mobile {
        display: block;
      }
    }
  }
}
