/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  position: relative;
  background: color-var(bg, 3);
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;

  &__description {
    padding-top: $inuit-global-spacing-unit-huge;
    padding-bottom: $inuit-global-spacing-unit-huge;
    background: color-var(bg, 2);
    display: flex;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: 50vw;
      height: 100%;
      z-index: 2;
      background-color: color-var(bg, 2);
    }
  }

  &__log-wrapper,
  &__inf-school {
    position: relative;
    z-index: 2;
  }

  &__log {
    width: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-tiny;
    height: auto;
  }

  &__inf-school {
    padding-left: $inuit-global-spacing-unit-huge;
  }

  &__menu-wrapper {
    padding-top: $inuit-global-spacing-unit-huge;
    color: color-var(font, 3);

    .menu-item {
      color: rgba(color-var(font, 3), 0.5);
    }

    h4 {
      font-family: $global-heading-font-family;
      margin-bottom: $inuit-global-spacing-unit-big
    }

    .wp-block-social-links {
      list-style-type: none;
      padding: 0;
      margin: 0;

      .wp-social-link {
        display: inline-block;
        vertical-align: middle;
        margin: 0 $inuit-global-spacing-unit-small $inuit-global-spacing-unit-tiny 0;

        svg {
          width: $inuit-global-spacing-unit-great;
          height: $inuit-global-spacing-unit-great;
          fill: color-var(font, 3);
          transition: all 0.2s ease-out;
        }

        &:hover {
          svg {
            fill: color-var(font, 1);
          }
        }
      }

    }
  }

  &__multisite-btn-mobile {
    display: none;
  }

  &__school-choosen-wrapper {
    position: relative;
    height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
    background-color: color-var(bg, 7);

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: 50vw;
      height: 100%;
      z-index: 2;
      background-color: color-var(bg, 7);
    }
  }

  &__copy-wrapper {
    position: relative;
    height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
    background-color: color-var(bg, 8);

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: left;
      width: 80vw;
      height: 100%;
      z-index: 2;
      background-color: color-var(bg, 8);
    }
  }

  &__front-item {
    position: relative;
    z-index: 4;
    line-height: 1;
  }

  &__simple-link {
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  &__sitename {
    a:hover, a:focus {
      color: inherit;
    }
  }

  @include mq($until:medium) {
    &__wrapper {
      padding: 0;
    }

    &__description {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 0;

      &:before {
        display: none;
      }
    }

    &__inf-school {
      padding-left: 0;
      text-align: center;
      margin-top: $inuit-global-spacing-unit-great;
    }

    &__multisite-btn-mobile {
      display: block;
      width: 100%;
      padding: $inuit-global-spacing-unit 0;
      text-align: center;
      background-color: color-var(bg, 7);
    }

    &__school-choosen-wrapper {
      display: none;
    }

    &__copy-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: $inuit-global-spacing-unit-big 0 $inuit-global-spacing-unit;

      &:before {
        display: none;
      }
    }

    &__copy-text {
      display: block;
      margin-top: $inuit-global-spacing-unit-big;
    }

    &__menu-wrapper {
      text-align: center;
      order: 2;
      padding-top: 0;
      padding-bottom: $inuit-global-spacing-unit-great;

      &--social-icon {
        order: 1;
        padding-top: $inuit-global-spacing-unit-great;
      }
    }
  }
}
