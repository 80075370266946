.wpml-ls-last-item {
  margin-left: 5px !important;
}

.wpml-ls-last-item a:before {
  content: '/';
  font-size: 10px;
  vertical-align: top;
  padding-top: 3px;
  display: inline-block;
  margin-right: 5px !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .wpml-ls-menu-item {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  .c-main-nav {
    text-align: center;
  }

  .wpml-ls-last-item a:before {
    color: #000 !important;
  }
}

.wpml-ls-menu-item a:after {
  display: none !important;
}

.wpml-ls-current-language a,
.wpml-ls-menu-item a:hover {
  color: #6deed8 !important;
}

.c-score__logo {
  top: 10px;
  right: 20px;
}

.c-score__desc {
  min-height: 5em;
}

.u-recapchta-info {
  padding: 15px 0;
  max-width: 300px;
  font-size: 0.6em !important;
  opacity: 0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-margin-left--auto {
  margin-left: auto;
}

@include mq($until: medium) {
  .u-padding-horizontal-big--mobile {
    padding: 0 $inuit-global-spacing-unit-big 0 ($inuit-global-spacing-unit-big + $inuit-global-spacing-unit-great) !important;
  }
}

.has-v-image {
  @include mq($from: large) {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + 80px);
      position: absolute;
      top: -230px;
      left: 0;
      background: url('../assets/images/svg/fala-gray.svg') no-repeat left top;
      z-index: 0;
    }

    &--main::before {
      background-image: url('../assets/images/svg/fala-blue.svg');
    }

    .is-pultusk &--main::before {
      background-image: url('../assets/images/svg/fala-pultusk.svg');
    }

    .is-sgtih &--main::before {
      background-image: url('../assets/images/svg/fala-sgtih.svg');
    }
  }
}

.home .c-slider-simple--arrow-top .splide__arrows {
  top: 160px;
}

.u-select-max-width {
  @include mq($until: medium) {
    max-width: 200px;
  }
}

main a {
  color: color_var(font, 5);
  text-decoration: underline;
}

.ks-header__list-content {
  a {
    display: block;
    color: color_var(font, 1);
    text-decoration: none;

    &:not(:last-child) {
      margin-bottom: $inuit-global-spacing-unit-small;
    }

    &:hover,
    &:focus {
      color: color_var(font, 5);
      text-decoration: underline;
    }
  }
}

.ks-answer__single--image {
  height: 260px;

  label {
    display: inline-block !important;
  }
}

.ks-quiz__desc-text a {
  color: inherit;

  &:hover {
    color: inherit;
    opacity: 0.9;
  }
}

.is-sgtih .c-button--promotion {
  background-color: color-var(sub3, 6);
}

.has-inline-color {
  &.has-error-color {
    color: color-var(font, 8) !important;
  }
}

.wp-block-social-link-label {
  display: none;
}
