	 		
:root{
        	
    --color-primary-button: var(--color-main-1);		
}

html.is-pultusk{
    --color-main-1: var(--color-sub2-5);
    --color-sub-1: var(--color-sub2-1);
    --color-sub-2: var(--color-sub2-2);
    --color-sub-3: var(--color-sub2-3);
    --color-sub-4: var(--color-sub2-4);
    --color-primary-button: var(--color-sub2-6);
}
html.is-sgtih{
    --color-main-1: var(--color-sub3-5);
    --color-sub-1: var(--color-sub3-1);
    --color-sub-2: var(--color-sub3-2);
    --color-sub-3: var(--color-sub3-3);
    --color-sub-4: var(--color-sub3-4);
    --color-primary-button: var(--color-sub3-6);
}