.c-filter-inner-content {
  position: relative;
  padding-bottom: 0;

  &--disable-shadow {
    .c-single-inner-content {
      box-shadow: none;
    }
  }

  &--disable-padding {
    .c-single-inner-content {
      padding: 0;

      &__container {
        padding: 0 0 $inuit-global-spacing-unit-big;
      }
    }
  }

  &__container {
    padding: 0;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__btn-wrapper {
    text-align: center;

    &--left {
      text-align: left;
    }
  }

  &__btn {
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  @include mq($until: medium) {
    &__btn {
      display: block;
      margin: $inuit-global-spacing-unit-tiny auto;
    }
  }
}
