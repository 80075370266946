.c-post-filter {

  &__mobile-additional-title {
    display: none;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    z-index: 10;

    @include mq($until: medium) {
      flex-direction: column-reverse;
    }
  }

  &__center-category {
    text-align: center;
    width: 100%;
  }

  &__title-cat {
    margin-top: $inuit-global-spacing-unit-great;
    background-color: color-var(bg, 15);
  }

  &__item {
    margin-top: $inuit-global-spacing-unit-great;
  }

  .c-article {
    height: 100%;
    opacity: 0;
    animation: changeOpacity 0.4s ease-in-out forwards;
  }

  .is-loading .c-article {
    position: relative;

    &:after {
      content: '';
      background-color: color-var(bg, 10);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }

  &--grid {
    display: flex;
    flex-wrap: wrap;

    @include mq($from: medium) {
      display: grid;
      grid-gap: $inuit-global-spacing-unit-great;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 0;

      .c-article {
        height: auto;
      }
    }
  }

  @include mq($until: medium) {
    &__mobile-additional-title {
      display: block;
    }

    &__center-category-mobile {
      text-align: center;
    }

    &__nav {
      &--change-setting {
        flex-direction: column;
        align-items: center;
      }
    }

    &__sort-wrapper {
      margin-top: 30px;

      .c-label.disable-active-option {
        option.disable-mobile {
          display: none;
        }
      }
    }
  }
}
