.c-breadcrumbs {
  @include inuit-font-size($inuit-font-size-h5);
  color: color-var(font, 2);
  padding: $inuit-global-spacing-unit-large 0;
  margin-top: 50px;
  @include mq($until: large) {
    display: none;
  }

  &__wrapper {
    position: relative;
    z-index: 3;
  }

  a,
  span {
    display: inline-block;
  }

  a {
    color: color-var(font);
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  .breadcrumb {
    &_last {
      margin-left: $inuit-global-spacing-unit-tiny;
    }
  }
}
