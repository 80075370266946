.c-calendary-add-links {
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit $inuit-global-spacing-unit-micro;
  margin: $inuit-global-spacing-unit-small 0;
  border-radius: $global-radius;
  border: $global-border solid color-var(border);
  text-align: center;

  &__title {
    display: block;
    margin-bottom: $inuit-global-spacing-unit-tiny;
    font-family: $global-heading-font-family;
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    margin: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-tiny;
    line-height: 1;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      color: color_var(font, 5);
    }
  }
}