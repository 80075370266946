/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.has-text-align-center,
.u-text-center {
  text-align: center;
}

.has-text-align-left,
.u-text-left {
  text-align: left;
}

.has-text-align-right,
.u-text-right {
  text-align: right;
}
