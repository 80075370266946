.c-description-with-widget {
  &__right-column {
    padding-left: $inuit-global-spacing-unit-huge;
    margin-top: -$inuit-global-spacing-unit-huge;
  }

  &__image-wrapper {
    text-align: center;
  }

  &__floating-content {
    position: sticky;
    top: $inuit-global-spacing-unit-massive;
    left: 0;
  }

  @include mq($until: medium) {
    &__floating-content {
      position: relative;
      top: 0;
    }

    &__title-wrap {
      order: 1;
      text-align: center;
    }

    &__right-column {
      padding-left: 0;
      order: 2;
      margin-top: 0;
    }

    &__left-column {
      order: 3;
    }
  }
}
