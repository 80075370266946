.c-promobar {
  background-color: color_var(bg, 13);
  width: 100%;
  padding: $inuit-global-spacing-unit-small 0;

  &__text {
    color: color_var(font, 3);
    margin: 0 0 0 $inuit-global-spacing-unit-small !important;
    @include inuit-font-size($inuit-font-size-h5 - 2px);

    @include mq($from: medium) {
      @include inuit-font-size($inuit-font-size-h5);
    }

    svg {
      width: auto;
      height: 8px;
    }

    svg * {
      fill: color_var(font, 3) !important;
    }
  }

  .is-pultusk & {
    background-color: color_var(sub2, 6);
  }

  .is-sgtih & {
    background-color: color_var(sub3, 6);
  }
}
