.c-why-worth {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &__container {
    @include mq($until: large) {
      padding: 0;
    }

    .c-slider-simple {
      padding-bottom: 0;

      @include mq($until: large) {
        padding-top: 0;
      }
    }

    .splide__arrows {
      top: 40%;

      @include mq($from: large) {
        top: 75%;
      }
    }

    .splide__arrow {
      background-color: color_var(bg, 1);

      svg {
        fill: color_var(font, 5);
      }
    }

    .splide__arrow--prev {
      left: 0 !important;

      @include mq($from: large) {
        left: $inuit-global-spacing-unit;
      }
    }

    .splide__arrow--next {
      right: 0 !important;

      @include mq($from: large) {
        right: $inuit-global-spacing-unit;
      }
    }
  }

  &__title,
  &__advantage-box {
    text-align: center;
  }

  &__advantages {
    overflow: hidden;
  }

  &__advantage-box-wrapper {
    margin: $inuit-global-spacing-unit-massive auto 0;
    @include mq($from: large) {
      max-width: 60%;
    }
  }

  @include mq($until: 'medium') {
    &__advantage-box-title {
      padding: 0 $inuit-global-spacing-unit-huge +
        $inuit-global-spacing-unit-small;
    }
  }
  .c-slider-simple__item-carousel:not(.is-visible) {
    opacity: 0.2;
  }
  .c-slider-simple--carousel {
    .splide__track {
      overflow: visible;
    }
  }
}
