.c-contact-data {
  box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  border-radius: $global-radius;

  &__container {
    padding: 0 $inuit-global-spacing-unit-great;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-large;
  }

  &__map-wrapper {
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  @include mq($until: medium) {
    &__container {
      padding: 0 $inuit-global-spacing-unit-big;
    }

    &__map-wrapper {
      height: 220px;
    }

    &__title {
      text-align: center;
    }
    &__data-wrapper{
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

}
