.c-box-organization {
  margin-top: $inuit-global-spacing-unit-great;

  @include mq($until: medium) {
    margin-top: $inuit-global-spacing-unit-small;
  }

  &__container {
    padding: 0;
  }

  &__inner {
    align-items: stretch;
  }

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    border-radius: $global-radius;
    background-color: color-var(bg, 6);
  }

  &__title {
    width: 100%;
    margin-top: $inuit-global-spacing-unit-tiny;
    text-align: center;
    font-family: $global-heading-font-family;
    font-weight: $global-strong-font-weight;
  }

  &__inner-block {
    padding-top: $inuit-global-spacing-unit-big;
    height: 100%;
    padding-left: $inuit-global-spacing-unit-huge;

    @include mq($until: medium) {
      padding-top: $inuit-global-spacing-unit-tiny;
    }
  }

  @include mq($until: medium) {
    &__img-wrapper {
      padding: $inuit-global-spacing-unit-small 0;
      margin: 0 $inuit-global-spacing-unit;
    }

    &__inner {
      flex-wrap: wrap;
    }

    &__inner-block {
      padding-left: 0;
    }

  }
}
