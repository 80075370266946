.c-multiple-column {
  position: relative;

  &--disable-margin-bottom {
    padding-bottom: 0;
  }

  &__inner {
    margin-top: $inuit-global-spacing-unit-great;

    @include mq($until: medium) {
      margin-top: $inuit-global-spacing-unit-small;
    }

    &--gray {
      padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
      background-color: color-var(bg, 5);
      border-radius: $global-radius;
    }
  }

  &__inner-col {
    padding-right: $inuit-global-spacing-unit-large + $inuit-global-spacing-unit-micro;
  }
}
