/* ==========================================================================
   #LINK
   ========================================================================== */

.o-link {
  color: inherit;
  text-decoration: underline;

  &--main-color {
    color: color-var(font, 5);
  }

  &:hover {
    color: color-var(font, 4);
  }

  &--visible {
    text-decoration: none;
    color: color-var(font, 4);

    &:hover {
      text-decoration: underline;
    }
  }
}
