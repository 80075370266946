.c-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include mq($from: medium) {
    align-items: center;
  }

  &:before {
    @include absolutePseudoContent();
    width: 100%;
    height: 100%;
    background-color: color_var(bg, 11);
    opacity: 0.8;
  }

  &__content {
    width: 100%;
    max-width: 970px;
    margin: $inuit-global-spacing-unit-tiny;
    padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great;
    background-color: color_var(bg, 1);
    border-radius: $global-radius;
    position: relative;
    z-index: 1000;
    @include mq($from: medium) {
      padding: ($inuit-global-spacing-unit-big + $inuit-global-spacing-unit-huge)
        $inuit-global-spacing-unit-massive;
      margin: 0;
    }

    &--scroll {
      max-height: 90vh;
      overflow-y: auto;
    }

    &--full {
      max-width: 1200px;
      width: auto;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit-big;
    @include inuit-font-size($inuit-font-size-h4 + 2px);
    @include mq($from: medium) {
      @include inuit-font-size($inuit-font-size-h3 + 2px);
      margin: 0 ($inuit-global-spacing-unit-big + $inuit-global-spacing-unit-huge)
        ($inuit-global-spacing-unit-big + $inuit-global-spacing-unit-huge);
    }
  }

  &__close {
    width: 14px;
    height: 14px;
    position: absolute;
    top: $inuit-global-spacing-unit;
    right: $inuit-global-spacing-unit;
    cursor: pointer;
    @include mq($from: medium) {
      top: $inuit-global-spacing-unit-great;
      right: $inuit-global-spacing-unit-great;
    }

    &:before, &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: color-var(bg, 11);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(135deg);
    }
  }

  &__prev, &__next {
    position: absolute;
    top: 50%;
    cursor: pointer;
    background-color: color-var(bg, 2);
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.181149);

    svg {
      width: 32px;
      height: 32px;

      * {
        fill: color-var(font, 3);
      }
    }

    &.is-disabled {
      cursor: default;
      pointer-events: none;
      background-color: color-var(func, 6);
    }

    @include mq($until: medium) {
      width: 40px;
      height: 40px;

      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__prev {
    left: 1rem;
    transform: translateY(-50%) rotate(180deg);

    @include mq($until: medium) {
      left: 0.25rem;
    }
  }

  &__next {
    right: 1rem;
    transform: translateY(-50%);

    @include mq($until: medium) {
      right: 0.25rem;
    }
  }

  &__img {
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 80vh;
    margin: 0 auto;
    display: block;

    @include mq($until: medium) {
      padding: $inuit-global-spacing-unit-small;
    }
  }

  .c-button {
    @include mq($from: medium) {
      margin-top: $inuit-global-spacing-unit-great;
    }
  }
}
