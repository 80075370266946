.c-major-box {
  position: relative;
  margin: 0;
  padding-bottom: $inuit-global-spacing-unit-massive;
  background-color: color-var(bg, 1);
  box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit rgba(0, 0, 0, 0.128975);
  border-radius: $global-radius;
  overflow: hidden;
  height: 100%;

  &__img-wrapper {
    display: block;
    width: 100%;
    height: 220px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  &__desc {
    padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-big;
  }

  &__title {
    min-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;
  }

  &__btn-wrapper {
    position: absolute;
    bottom: $inuit-global-spacing-unit-big;
    left: 0;
    width: 100%;
  }
}
