.c-photo-carousel {
  display: block;
  position: relative;

  &__wrapper {
    position: relative;
    display: flex;
    height: 350px;
    margin: 0;
    padding: 0;
    justify-content: center;
  }

  &__item {
    display: none;
    opacity: 0;
    align-items: center;
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-small;
    border-radius: $global-radius;
    overflow: hidden;
    visibility: hidden;

    &.is-active {
      display: flex;
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear;
    }

    &.is-opacity {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      opacity: 0.2;
      mix-blend-mode: normal;
      visibility: visible;

      &.left {
        left: 0;
        transform: translate(-104%, -50%);
      }

      &.right {
        right: 0;
        transform: translate(104%, -50%);
      }
    }
  }

  &__img {
    box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small $inuit-global-spacing-unit-micro rgba(0, 0, 0, 0.4);
    border-radius: $global-radius;
    max-height: 100%;
    width: auto;
  }

  @include mq($until: 'medium') {
    &__wrapper {
      height: 200px;
      margin: 0 (-$inuit-global-spacing-unit-huge) 0;
    }

    &__item {
      &.is-active {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
