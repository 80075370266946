.c-search {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: max-content;
  left: -12px;
  overflow: hidden;
  z-index: 5;
  
  @include mq($until: large) {
    top: 60px;
    left: auto;
    right: $inuit-global-spacing-unit;
  }
  
  @include mq($from: large) {
    .c-header.is-active & {
    }
  }

  &__form {
    transform: translateX(100%) scaleX(0);

    &.is-active {
      animation: slideSearch 400ms ease-in-out forwards;
    }

    &:not(.is-active) {
      animation: hideSearch 300ms ease-in-out forwards;
    }
  }

  &__input {
    padding-left: $inuit-global-spacing-unit-great;
    padding-right: 64px;
    height: 36px;
    width: calc(100vw - 32px);
    @include mq($from: large) {
      width: 270px;
    }
  }

  &__submit {
    @include inuit-font-size($inuit-font-size-h5);
    font-weight: 500;
    color: color_var(font, 5);
    background: none;
    border: none;
    height: 36px;
    padding-right: $inuit-global-spacing-unit-small;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  &__label {
    margin: 0;

    .c-icon::before {
      left: $inuit-global-spacing-unit-tiny;
      top: 55%;
    }
  }
  &-list {
    &__header {
      padding: $inuit-global-spacing-unit-huge 200px;
      @include mq($until: large) {
        padding: $inuit-global-spacing-unit-large;
      }
      box-shadow: 0 $inuit-global-spacing-unit-tiny
        $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    }
    &__input {
      border-color: color-var(border, 3);
    }
    &__button {
      margin: 0;
      margin-left: 15px;
      padding: 12px 40px;
    }
    &__sidebar,
    &__item {
      box-shadow: 0 $inuit-global-spacing-unit-tiny
        $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
      margin-top: $inuit-global-spacing-unit-great;
      color: color-var(font, 1);
      text-decoration: none;
      display: block;
      padding: $inuit-global-spacing-unit-large;
      input[type='radio'] {
        margin-right: 10px;
      }
      .c-search-item__radio {
        display: block;
        margin-bottom: 15px;
      }
    }
  }
}

@keyframes slideSearch {
  from {
    transform: translateX(100%) scaleX(0);
  }

  to {
    transform: translateX(0) scaleX(1);
  }
}

@keyframes hideSearch {
  from {
    transform: translateX(0) scaleX(1);
  }

  to {
    transform: translateX(100%) scaleX(0);
  }
}
