.c-students-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: transparentize(#000000, 0.2);

  &__wrapper {
    background: color-var(bg, 1) url('../assets/images/svg/fala-full-tr.svg') no-repeat center center;
    background-size: cover;
    width: 100%;
    max-width: 970px;
    max-height: 90vh;
    overflow-y: auto;
    padding: $inuit-global-spacing-unit-massive
      ($inuit-global-spacing-unit-huge * 5)
      ($inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big);
    position: relative;

    @include mq($until: medium) {
      padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-small
    }

    html.is-pultusk & {
      background-image: url('../assets/images/svg/fala-full-tr_pultusk.svg');
    }

    html.is-sgtih & {
      background-image: url('../assets/images/svg/fala-full-tr_sgtih.svg');
    }
  }

  &__content {
    position: relative;
  }

  .box-student__image-container {
    left: -128px;
    transform: translateY(-40%);

    @include mq($until: medium) {
      position: static;
      margin: 0 auto $inuit-global-spacing-unit-large;
      transform: none;
    }
  }
}
