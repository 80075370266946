.c-loader {
  display: none;

  &__item {
    background-color: color-var(bg, 10);
    width: 100%;
    height: 100px;
    margin-bottom: $inuit-global-spacing-unit-big;
    position: relative;

    &::before {
      @include absolutePseudoContent();
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: color-var(bg, 6);
    }

    &--big {
      height: 200px;
    }

    &--major {
      height: 400px;
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    &--filters {
      height: 1200px;
    }
  }

  &.is-loading {
    display: block;

    .c-loader__item::before {
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: linear-gradient(to right, color-var(bg, 6) 8%, color-var(bg, 5) 38%, color-var(bg, 6) 54%);
      background-size: 1000px 640px;
    }
  }
}
