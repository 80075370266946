.c-major-lists {
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + #{$inuit-global-spacing-unit-massive});
    position: absolute;
    top: -230px;
    left: 0;
    background: url('../assets/images/svg/fala-blue.svg') no-repeat center top;

    .is-sgtih & {
      background-image: url('../assets/images/svg/fala-sgtih.svg');
    }

    .is-pultusk & {
      background-image: url('../assets/images/svg/fala-pultusk.svg');
    }
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-huge;
    text-align: center;
  }

  &__single {
    padding: $inuit-global-spacing-unit-great;
    margin-bottom: $inuit-global-spacing-unit-huge +
      $inuit-global-spacing-unit-big;
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great
      rgba(0, 0, 0, 0.128975);
    background-color: color-var(bg, 1);
  }

  &__title-school {
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__logo-page {
    max-width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
    height: auto;
  }

  &__boxes {
    @media screen and (min-width: 1000px) {
      display: flex;
    }
    // &::after {
    //   content: '';
    //   display: block;
    //   clear: both;
    // }
  }
  .c-major-lists__boxes-odd,
  .c-major-lists__boxes-even {
    flex: 0 0 50%;
    min-width: 50%;
    max-width: 50%;
    @media screen and (max-width: 1000px) {
      flex: 0 0 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
  &__box-wrapper {
    // float: right;
    // width: 50%;
    margin-bottom: $inuit-global-spacing-unit-great;
    .c-major-lists__boxes-odd & {
      padding-right: 15px;
      @media screen and (max-width: 1000px) {
        padding-right: 0;
      }
    }
    .c-major-lists__boxes-even & {
      padding-left: 15px;
      @media screen and (max-width: 1000px) {
        padding-left: 0;
      }
    }
    // &--left {
    //   float: left;
    //   padding-right: $inuit-global-spacing-unit;
    // }

    // &--right {
    //   padding-left: $inuit-global-spacing-unit;
    // }

    &--clear {
      clear: both;
    }
  }

  &__box {
    padding: $inuit-global-spacing-unit-large;
    border-radius: $global-radius;
    border: $global-border solid color-var(border, 1);
  }

  &__items {
    margin-bottom: 0;
  }

  &__item {
    display: block;
    width: 100%;
    padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-big;
    border-radius: $global-radius;

    &.o-link--main-color {
      color: color-var(sub, 8);
    }

    .c-major-lists__single--blog-2 & {
      &.o-link--main-color {
        color: color-var(sub2, 1);
      }
    }
    .c-major-lists__single--blog-3 & {
      &.o-link--main-color {
        color: color-var(sub3, 1);
      }
    }
    &--gray {
      .c-major-lists__single--blog-1 & {
        background-color: color-var(sub, 6);
        &.o-link--main-color {
          color: color-var(sub, 8);
        }
      }
      .c-major-lists__single--blog-2 & {
        background-color: color-var(sub2, 7);
        &.o-link--main-color {
          color: color-var(sub2, 1);
        }
      }
      .c-major-lists__single--blog-3 & {
        background-color: color-var(sub3, 7);
        &.o-link--main-color {
          color: color-var(sub3, 1);
        }
      }
    }
  }

  @include mq($until: medium) {
    &::after {
      display: none;
    }

    &__header {
      display: block;
      margin-bottom: $inuit-global-spacing-unit-large;
      text-align: center;
    }

    &__title-school {
      margin-bottom: $inuit-global-spacing-unit-large;
    }

    &__single {
      padding: 0;
      box-shadow: none;
    }

    &__box-wrapper {
      width: 100%;
      padding: 0;
    }

    &__box {
      padding: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-small;
    }

    &__item {
      padding: $inuit-global-spacing-unit;
    }
  }
}
