.c-double-column {
  &__title {
    margin-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
    text-align: center;
  }

  &__boxes-wrapper {
    margin-top: $inuit-global-spacing-unit-huge;

    &--even-odd {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      .c-box-icon--double-column {
        width: 100%;
        float: none;
      }
    }

    &:after {
      content: " ";
      display: block;
      clear: both;
    }
  }
}
