.c-major-card {
  $self: &;
  margin-bottom: $inuit-global-spacing-unit-great;

  &__title {
    margin-right: $inuit-global-spacing-unit-massive;

    @include mq($until: medium) {
      @include inuit-font-size($inuit-font-size-h3 - 2px, $inuit-line-height-h3);
    }
  }

  &__school {
    margin-right: $inuit-global-spacing-unit-massive;

    @include mq($until: medium) {
      @include inuit-font-size($inuit-font-size-h4 - 2px, $inuit-line-height-h4);
    }
  }

  &__taxonomies {
    margin-bottom: $inuit-global-spacing-unit-great;
    padding: $inuit-global-spacing-unit;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0.15;
      border-radius: $global-radius;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  &__date-box {
    background: url('../assets/images/svg/event_black_calendary.svg') no-repeat center left $inuit-global-spacing-unit-big;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small 50px;
    display: inline-block;
    position: relative;
    margin-bottom: $inuit-global-spacing-unit-great;
    border-radius: $global-radius;
    overflow: hidden;
    @include mq($until: medium) {
      display: block;
      margin-bottom: $inuit-global-spacing-unit;
    }

    &:before {
      content: '';
      background-color: #e2d201;
      opacity: 0.2;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  &--vistula {
    background: color-var(bg, 1) url('../assets/images/svg/vistula.svg') no-repeat top $inuit-global-spacing-unit-big right $inuit-global-spacing-unit-big;

    #{$self}__taxonomies:before {
      background-color: color-var(sub, 8);
    }
  }

  &--sgtih {
    background: color-var(bg, 1) url('../assets/images/svg/sgtih.svg') no-repeat top $inuit-global-spacing-unit-big right $inuit-global-spacing-unit-big;

    #{$self}__taxonomies:before {
      background-color: color-var(sub3, 5);
    }
  }

  &--pultusk {
    background: color-var(bg, 1) url('../assets/images/svg/pultusk.svg') no-repeat top $inuit-global-spacing-unit-big right $inuit-global-spacing-unit-big;

    #{$self}__taxonomies:before {
      background-color: color-var(sub2, 5);
    }
  }

  html[lang='en'] &--vistula {
    background-image: url('../assets/images/svg/vistula-en.svg');
  }

  html[lang='en'] &--sgtih {
    background-image: url('../assets/images/svg/sgtih-en.svg');
  }
}
