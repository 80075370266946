.c-candidate-inf {
  position: relative;
  padding-bottom: $inuit-global-spacing-unit-big + $inuit-global-spacing-unit-huge;

  @include mq($until: medium) {
    padding-bottom: $inuit-global-spacing-unit-small;

    .c-label__container {
      margin-bottom: $inuit-global-spacing-unit-small;
    }

    .c-simple-buttons {
      margin: 0;
    }

    .o-section {
      padding: $inuit-global-spacing-unit-small 0;
    }
  }

  &__header {
    margin-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
    text-align: center;

    @include mq($until: medium) {
      margin-bottom: $inuit-global-spacing-unit-big !important;
    }

    &--inner {
      margin-bottom: 0;
      padding-top: $inuit-global-spacing-unit-great;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + #{$inuit-global-spacing-unit-massive});
    position: absolute;
    top: -230px;
    left: 0;
    background: url('../assets/images/svg/fala-blue.svg') no-repeat left top;
    z-index: -1;

    .is-sgtih & {
      background-image: url('../assets/images/svg/fala-sgtih.svg');
    }

    .is-pultusk & {
      background-image: url('../assets/images/svg/fala-pultusk.svg');
    }
  }

  &__blocks-wrapper {
    padding: $inuit-global-spacing-unit-big 0 0;
    box-shadow: 0 0 $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    background-color: color-var(bg, 1);
    border-radius: $global-radius;

    &--paddin-none {
      padding: 0;
    }

    &--thin {
      @include mq($from: large) {
        max-width: 83.333% !important;
        margin-left: 8.333% !important;
      }
    }
  }

  &__additional-inner {
    margin-top: -$inuit-global-spacing-unit-big;
    padding: 0 $inuit-global-spacing-unit-tiny;
    box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  }

  &__inner-block {
    // padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit $inuit-global-spacing-unit-tiny;
    box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);

    &--flex {
      flex-wrap: wrap;
    }

    &--thin {
      padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-big 50px;

      @include mq($from: large) {
        padding: 50px 100px;
      }
    }
  }

  &__children-wrapper {
    padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit $inuit-global-spacing-unit-tiny;
    box-shadow: 0 $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  }

  &__children-lists {
    margin-top: $inuit-global-spacing-unit-huge;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--margin-none {
      margin: 0;
    }

    &-item {
      padding: 0 $inuit-global-spacing-unit;
      margin-bottom: $inuit-global-spacing-unit-great;

      .c-article {
        height: 100%;

        .c-article__excerpt {
          min-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-tiny;
        }
      }
    }
  }


  &__menu {
    // padding-left: $inuit-global-spacing-unit;
    padding-left: 0;
  }

  &__inner-items {
    padding: 0 $inuit-global-spacing-unit;
    z-index: 4;

    &--rm-padding {
      padding: 0;
    }
  }

  @include mq($until: medium) {
    padding-bottom: 0;

    a {
      word-wrap: break-word;
    }

    &__header {
      margin-bottom: $inuit-global-spacing-unit-great;
      padding: 0 $inuit-global-spacing-unit;
    }

    &__children-wrapper {
      padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-tiny;
    }

    &__menu {
      position: sticky;
      left: auto;
      top: 0;
      background: color-var(bg, 1);
      order: 1;
      padding: 0 $inuit-global-spacing-unit-big;
      z-index: 88;
    }

    &__inner-items {
      order: 2;
      padding: 0;
    }

    &__container {
      padding: 0;
    }

    &__children-lists {
      &-item {
        width: 100%;
      }
    }

    &__additional-inner {
      overflow: hidden;
    }
  }
}
