.c-navigation-scroll {
  &__list-menu {
    margin: 0;
    height: 100%;
    line-height: $inuit-global-spacing-unit-massive;
  }

  &__select-menu {
    display: none;
  }

  &__item {
    height: 100%;
    margin: 0 $inuit-global-spacing-unit-large 0 0;
  }

  &__link {
    position: relative;
    display: block;
    height: 100%;
    color: color-var(font, 5);
    transition: all 0.2s ease-out;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      border-bottom: 4px solid color-var(bg, 3);
      border-radius: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-big;
      transition: all 0.2s ease-out;
    }

    &.is-active,
    &:hover {
      color: color-var(font, 1);

      &::after {
        width: 100%;
      }
    }

  }

  @include mq($until:medium) {
    flex-grow: 1;
    padding-right: $inuit-global-spacing-unit-small;

    &__list-menu {
      display: none;
    }

    &__select-menu {
      display: block;

      .c-label {
        margin-bottom: $inuit-global-spacing-unit-big;
      }
    }
  }
}
