.c-shortcuts {
  &__container {
    padding: 0 $inuit-global-spacing-unit-great;
  }

  &__title {
    margin-bottom: 0;
    margin-right: $inuit-global-spacing-unit;
  }

  &__btn {
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  @include mq($until: medium) {
    padding: $inuit-global-spacing-unit 0;

    &--mobile-center {
      .c-shortcuts__btn {
        text-align: center;
      }
    }

    &__container {
      padding: 0 $inuit-global-spacing-unit-big;
    }

    &__wrapper {
      display: block;
      text-align: center;
    }

    &__title {
      margin-bottom: $inuit-global-spacing-unit;
      font-size: $inuit-global-spacing-unit;
    }
  }

  @include mq($until: small) {
    &__btn {
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}
