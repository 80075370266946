/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  @include clearfix();
  margin-bottom: 2em;
  padding: $inuit-global-spacing-unit-huge 0 50px;
  background: #fff;
  @include mq($from: large) {
    padding: 50px 100px;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
  }

  &__author {
    &-name {
      margin-bottom: 0;
    }

    &-img {
      width: $inuit-global-spacing-unit * 4;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__date {
    color: color-var(font, 2);
  }

  &__image {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__title {
    text-align: center;
    font-weight: 500;
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__meta {
    margin-bottom: $inuit-global-spacing-unit-great;
    @include inuit-font-size($inuit-font-size-h5);
    font-family: $global-heading-font-family;
    font-weight: $global-heading-font-weight;
    color: color-var(font,4);
    text-align: center;
  }
}

.c-post__content {
  width: 100%;
  @extend .wp-block;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

@include mq($from: large) {
  .blog,
  .post-type-archive-news,
  .single-post,
  .single-news {
    background: #f4f4f4;
  }
}
