/* stylelint-disable value-keyword-case */
/* stylelint-disable property-no-vendor-prefix */
.c-multisite-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  overflow: hidden;
  display: none;

  &::before {
    @include absolutePseudoContent();
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: color-var(bg, 11);
    opacity: 0.8;
    z-index: -1;
  }

  &.is-active {
    display: block;
    -webkit-animation: fadeIn 500ms forwards;
    animation: fadeIn 500ms forwards;
  }

  // &__wrapper {
  //   padding-top: $inuit-global-spacing-unit-huge;
  // }

  &__btn {
    position: relative;
    z-index: 3;
    color: color-var(font, 3);
    padding: 0;
    border: 0;
    margin: 0;
    transition: all 0.2 ease-out;

    &-icon {
      display: inline-block;
      margin-left: $inuit-global-spacing-unit-tiny;

      &--top {
        transform: rotate(180deg);
      }

      svg * {
        fill: color-var(font, 3);
        transition: all 0.2 ease-out;
      }
    }

    &:hover,
    &:focus {
      background-color: transparent;

      &::before {
        display: none;
      }
    }
  }

  @include mq($until: medium) {
    &__wrapper {
      padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit 0 0;
    }
  }
}
