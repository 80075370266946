/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: color-var(bg, 3);
  position: relative;
  z-index: 1000;

  &.is-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    transform: translateY(-100%) scaleY(0);
    transition: transform 0ms;
  }

  &.is-active {
    transform: translateY(0) scaleY(1);
    transition: transform 400ms ease-in-out;
  }

  &.is-deactive {
    transform: translateY(-100%) scaleY(0);
    transition: transform 300ms ease-out;
  }

  &__wrapper {
    min-height: $inuit-global-spacing-unit-massive;
    position: relative;
    @include mq($until: large) {
      padding: 0;
    }
  }

  &__top {
    position: relative;
    background-color: color-var(bg, 8);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -32px;
      width: 100vw;
      height: 100%;
      z-index: -1;
      background-color: color-var(bg, 8);
    }

    &-spreader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .c-main-nav {
      gap: 0;
      &__link {
        font-weight: 500 !important;
        padding-left: 50px;
        margin-right: 24px;
        margin-bottom: 0;
      }
    }

    .c-header.is-active.is-fixed & {
      height: 48px;
    }
  }

  .t-transparent-header & {
    background: transparent;
    color: color-var(font, 3);
  }

  &__logo {
    height: 100px;
    @include mq($until: large) {
      height: 50px;
      margin-left: $inuit-global-spacing-unit;
    }

    .c-header.is-active.is-fixed & {
      @include mq($from: large) {
        height: 80px;
      }
    }

    &-container {
      display: flex;
      position: relative;

      @include mq($until: large) {
        align-items: stretch;
        align-content: center;
        height: 80px;
        background-color: color-var(bg, 2);
      }

      @include mq($from: medium, $until: large) {
        justify-content: space-between;

        &:before {
          content: none !important;
        }
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: -18px;
        right: 0;
        width: 50vw;
        height: 136px;
        z-index: -1;
        background-color: color-var(bg, 2);
        @include mq($until: medium) {
          display: none;
        }

        .c-header.is-active.is-fixed & {
          @include mq($from: large) {
            top: -38px;
          }
        }
      }
    }
  }

  &__sitename {
    @include inuit-font-size($inuit-font-size-h3);
    color: color-var(font, 3);
    padding-left: $inuit-global-spacing-unit-huge;
    font-weight: 700;
    margin: 0;
    @include mq($until: large) {
      @include inuit-font-size($inuit-font-size-h4, $inuit-font-size-h3);
      padding-left: $inuit-global-spacing-unit-big;
    }
    
    @media (min-width: 64em) and (max-width: 79.99em) {
    // @include mq($until: xlarge, $from: large) {
      font-size: 1rem;
    }

    .c-header.is-active.is-fixed & {
      @include mq($from: large) {
        line-height: $inuit-font-size-h2;
        margin-top: -$inuit-global-spacing-unit-big;
      }
    }

    a:hover,
    a:focus {
      color: inherit;
    }
  }

  &__hamburger {
    background: none;
    border: none;
    width: 18px;
    height: 12px;
    position: relative;

    &-container {
      width: 40px;
      min-width: 40px;
      background-color: color-var(bg, 8);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-line {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background-color: color-var(bg, 1);
      border-radius: $global-radius;
      transition: background-color 0.4s ease-in-out;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: color-var(bg, 1);
        border-radius: $global-radius;
        transition: transform 0.4s ease-in-out;
      }

      &:before {
        top: -4px;
      }

      &:after {
        bottom: -4px;
      }
    }

    &.is-active &-line {
      background-color: color-var(bg, 8);

      &:before {
        transform: translateY(4px) rotate(45deg);
      }

      &:after {
        transform: translateY(-4px) rotate(-45deg);
      }
    }
  }

  &__icons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $inuit-global-spacing-unit;
    margin-left: auto;
  }

  &__icon {
    width: auto;
    height: 18px;
    margin-left: $inuit-global-spacing-unit-large;
  }

  &__multisite-link {
    position: absolute;
    top: 118px;
    right: 0;
    width: 100%;
    min-height: 50px;
    padding-left: $inuit-global-spacing-unit-great;
    display: flex;
    align-items: center;

    &:before {
      @include absolutePseudoContent();
      width: 100vw;
      height: 100%;
      right: 0;
      background-color: color-var(bg, 7);
    }

    .c-header.is-active.is-fixed & {
      @include mq($from: large) {
        top: auto;
        bottom: -24px;
        left: 116px;

        &:before {
          content: none;
        }
      }
    }
  }
}
