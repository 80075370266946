.c-hero {
  position: relative;
  background-repeat: no-repeat;
  background-position: center right -100px;
  background-size: auto 610px;

  @include mq($until: medium) {
    overflow: hidden;
  }

  @media (min-width: 64em) and (max-width: 79.99em) {
  // @include mq($from: large, $until: xlarge) {
    background-position: center right -500px;
  }

  @media (min-width: 80em) and (max-width: 85.4275em) {
  // @include mq($from: xlarge, $until: xxlarge) {
    background-position: center right -400px;
  }

  @media (min-width: 85.4375) and (max-width: 99.99em) {
  // @include mq($from: xxlarge, $until: full) {
    background-position: center right -300px;
  }

  @media (min-width: 100em) and (max-width: 119.99em) {
  // @include mq($from: full, $until: xfull) {
    background-position: center right -200px;
  }

  &__title,
  &__subtitle {
    color: color-var(font, 3);
    margin-bottom: 0;
  }

  &__content {
    text-align: center;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &--small {
    height: 480px;
  }

  &--normal {
    height: 610px;
  }

  &--big {
    height: 600px;
  }

  &--full {
    height: 710px;
    padding-top: 110px;
    margin-top: -110px;
  }

  &--secondary {
    @include mq($until: large) {
      background-position: top left;
      background-size: contain;
      height: auto;
    }

    &:before {
      z-index: 2;
      content: '';
      display: block;
      width: 200%;
      height: 200%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: calc(50vw - 960px);
      background: url('../assets/images/svg/fala.svg') no-repeat top left;
      html.is-pultusk & {
        background-image: url('../assets/images/svg/fala_pultusk.svg');
      }
      html.is-sgtih & {
        background-image: url('../assets/images/svg/fala_sgtih.svg');
      }
      @include mq($until: large) {
        z-index: 10;
        height: 250px;
        left: 0;
        background-image: url('../assets/images/svg/fala-full.svg');
        background-size: contain;
        html.is-pultusk & {
          background-image: url('../assets/images/svg/fala-full_pultusk.svg');
        }
        html.is-sgtih & {
          background-image: url('../assets/images/svg/fala-full_sgtih.svg');
        }
      }
    }

    &:after {
      @include mq($from: large) {
        content: '';
        display: block;
        width: 200%;
        height: 200%;
        position: absolute;
        top: 100%;
        left: calc(50vw - 960px);
        pointer-events: none;
        z-index: 3;
        background: url('../assets/images/svg/fala_alt.svg') no-repeat top -610px
          left;
        html.is-pultusk & {
          background-image: url('../assets/images/svg/fala_pultusk_alt.svg');
        }
        html.is-sgtih & {
          background-image: url('../assets/images/svg/fala_sgtih_alt.svg');
        }
      }
    }

    .c-hero {
      &__content {
        text-align: left;
        max-width: 33.33%;
        @include mq($until: large) {
          margin-top: 250px;
          max-width: 100%;
          position: relative;

          &:before {
            @include absolutePseudoContent();
            z-index: -1;
            background-color: color_var(bg, 1);
            width: calc(100% + 40px);
            height: calc(100% + 40px);
            margin-left: -$inuit-global-spacing-unit-big;
            margin-top: -$inuit-global-spacing-unit-small;
          }
        }
        @include mq($from: large) {
          padding-right: $inuit-global-spacing-unit-big;
        }
      }

      &__container {
        justify-content: flex-start;
      }

      &__title {
        font-weight: 500;
        @include inuit-font-size($inuit-font-size-h0);
        @include mq($until: large) {
          @include inuit-font-size($inuit-font-size-h1);
        }
        line-height: 1.3em !important;
      }

      &__subtitle {
        font-weight: 400;
        font-family: $global-body-font-family;
        @include inuit-font-size($inuit-font-size-h4);
        margin-top: $inuit-global-spacing-unit-large;
        margin-bottom: $inuit-global-spacing-unit-large;

        &.c-hero__subtitle {
          color: color-var(font, 2) !important;
        }
      }
    }
  }

  // &:after {
  //   @include absolutePseudoContent();
  //   @include absPosition100();
  //   pointer-events: none;
  // }

  @each $type, $colors in $baseColors {
    $iter: 1;

    @each $color in $colors {
      &.u-color--#{$type}-#{$iter}:after {
        background-color: $color !important;
        opacity: 0.8;
      }

      $iter: $iter + 1;
    }
  }

  &__video {
    position: absolute;
    @include absPosition100();
    pointer-events: none;
    overflow: hidden;

    .c-embed {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;

      iframe {
        position: absolute;
        top: -70px;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.home.page {
  .c-hero {
    z-index: 2;
    .o-wrapper {
      z-index: 100;
    }
    & + .o-section,
    & + .o-section + .o-section {
      overflow: hidden;
      .o-wrapper {
        z-index: 100;
      }
    }
  }
  .c-hero--secondary .c-hero__title {
    font-size: 2rem !important;
  }
  .c-hero--secondary .c-hero__subtitle {
    font-size: 1rem !important;
  }
}

.has-v-sign,
.has-v-sign-2 {
  position: relative;
  &:before {
    z-index: 2;
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: calc(50vw - 960px);
    background: url('../assets/images/svg/fala_transparent.svg') no-repeat top
      left;
  }
  z-index: 2;
  position: relative;
  .o-wrapper {
    z-index: 100;
  }
  & + .o-section,
  & + .o-section + .o-section {
    overflow: hidden;
    .o-wrapper {
      z-index: 100;
    }
  }
}

.has-v-sign-2 {
  &:before {
    top: -498px;
    background: url('../assets/images/svg/fala_transparent_2.svg') no-repeat top left;
    @include mq($until: large) {
      display: none;
    }
  }
}