.c-school-inf-popup {
  position: relative;
  flex-wrap: wrap;
  padding: $inuit-global-spacing-unit-huge 0 $inuit-global-spacing-unit-huge 0;
  background-color: color-var(bg, 2);

  &--main {
    padding-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
  }

  &:first-child {
    &::before,
    & {
      border-radius: 0 $global-radius 0 0;
    }
  }

  &:last-child {
    &::before,
    & {
      border-radius: 0 0 $global-radius 0;
    }
  }

  &--1 {
    background-color: color-var(sub, 8);

    &::before {
      background-color: color-var(sub, 8);
    }

    .c-button--site {
      color: color-var(sub, 8);

      * {
        fill: color-var(sub, 8) !important;
      }
    }
  }

  &--2 {
    background-color: color-var(sub2, 5);

    &::before {
      background-color: color-var(sub2, 5);
    }

    .c-button--site {
      color: color-var(sub2, 5);

      * {
        fill: color-var(sub2, 5) !important;
      }
    }
  }

  &--3 {
    background-color: color-var(sub3, 5);

    &::before {
      background-color: color-var(sub3, 5);
    }

    .c-button--site {
      color: color-var(sub3, 5);

      * {
        fill: color-var(sub3, 5) !important;
      }
    }
  }

  &__log-wrapper {
    display: flex;
    align-items: center;
  }

  &__log {
    width: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-tiny;
    height: auto;
  }

  &__inf-inner-wrapper {
    padding-left: $inuit-global-spacing-unit-huge;
  }

  &__name {
    margin-bottom: 0;
  }

  &__mobile-btn {
    display: none;
  }

  &__major-wrapper {
    padding-left: $inuit-global-spacing-unit-big;
  }

  &__major-btn {
    display: none;
    margin-right: $inuit-global-spacing-unit-tiny;

    &.is-active {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .c-icon {
      svg {
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  &__hide-btn-wrap {
    position: relative;
    margin-top: $inuit-global-spacing-unit-huge;
    padding: $inuit-global-spacing-unit 0;
    background-color: color-var(bg, 7);

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100%;
      z-index: 2;
      background-color: color-var(bg, 7);
    }
  }

  @include mq($until: medium) {
    padding: $inuit-global-spacing-unit-great 0;

    &__log {
      width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-large;
    }

    &::before {
      display: none;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    &__major-wrapper,
    &__btn-desktop-wrapper {
      display: none;
    }

    &__mobile-btn {
      display: block;
    }

    &__log-wrapper {
      padding: 0 $inuit-global-spacing-unit-large;
    }

    &__hide-btn-wrap {
      &::before {
        display: none;
      }

      padding: $inuit-global-spacing-unit $inuit-global-spacing-unit-large;
      width: 100%;
      margin: 0;
      position: fixed;
      top: 0;
    }
  }
}
