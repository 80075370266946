/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-icon {
  display: inline-block;
  position: relative;

  @for $i from 0 through 8 {
    $deg: $i * 45;

    &--rotate-#{$deg} {
      transform: rotate(#{$deg}deg);
    }
  }

  &--left {
    padding-left: $inuit-global-spacing-unit-great !important;

    &:before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: auto;
      height: 24px;
    }
  }

  &--in-button {
    &::before {
      background: none;
      opacity: 1;
      padding-left: $inuit-global-spacing-unit-micro;
    }
  }

  &--without-padding {
    padding: 0 !important;
  }

  &--phone:before {
    content: url('../assets/images/svg/phone_black.svg');
  }

  &--phone-grey:before {
    content: url('../assets/images/svg/phone_grey.svg');
  }

  &--computer:before {
    content: url('../assets/images/svg/computer.svg');
  }

  &--computer-grey:before {
    content: url('../assets/images/svg/computer_grey.svg');
  }

  &--language:before {
    content: url('../assets/images/svg/language.svg');
  }

  &--language-grey:before {
    content: url('../assets/images/svg/language_grey.svg');
  }

  &--search:before {
    content: url('../assets/images/svg/search.svg');
  }

  &--search-grey:before {
    content: url('../assets/images/svg/search_grey.svg');
  }

  &--search-black:before {
    content: url('../assets/images/svg/search_black.svg');
  }

  &--tune:before {
    content: url('../assets/images/svg/tune.svg');
  }

  &--info:before {
    content: url('../assets/images/svg/info_black.svg');
  }

  &--vue {
    height: 35px;
    margin-left: $inuit-global-spacing-unit-micro;
    position: relative;

    &:before {
      height: 24px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-25%);
    }
  }
}
