.c-reverse-box {
  &__title {
    margin-bottom: $inuit-global-spacing-unit-huge;
    text-align: center;
  }

  &__single-box {
    margin-bottom: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
  }

  &__image-wrapper {
    &--center {
      text-align: center;
    }

    &--left {
      padding-right: $inuit-global-spacing-unit;
    }

    &--right {
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &__img {
    width: auto;

    &--full-width {
      width: 100%;
    }

  }

  &__description {
    &--left {
      padding-right: $inuit-global-spacing-unit-great;
    }

    &--right {
      padding-left: $inuit-global-spacing-unit-great;
    }
  }

  @include mq($until: medium) {
    &__single-box {
      flex-wrap: wrap;
    }

    &__description {
      padding: $inuit-global-spacing-unit-great 0 0;
      order: 2;
    }

    &__image-wrapper {
      order: 1;
      padding: 0;
    }
  }
}
