.o-section {
  padding: $inuit-global-spacing-unit-large 0;
  * {
    position: relative;
    z-index: 1;
  }

  &--without-padding {
    padding: 0;
  }
}
