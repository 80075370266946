/* stylelint-disable max-nesting-depth */
.c-paragraph {
  @include mq($until: medium) {
    .page &--simple-on-mobile {
      margin-bottom: 1.3rem;

      p:last-of-type {
        margin-bottom: 0 !important;
      }

      .c-simple__desc {
        margin-bottom: 0;
      }

      &:not(.is-expand) .c-paragraph--simple {
        height: 10.5 * $inuit-font-size-h4;
        overflow: hidden;
      }
    }

    &__expand,
    &__collapse {
      color: color_var(font, 5);
      pointer-events: all;
      cursor: pointer;
      text-decoration: underline;

      &::after {
        content: '';
        display: inline-block;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid color-var(font, 5);
        margin-left: $inuit-global-spacing-unit-micro;
        margin-bottom: $inuit-global-spacing-unit-micro / 2;
      }
    }

    &__collapse::after {
      border-top: none;
      border-bottom: 5px solid color-var(font, 5);
    }
  }
}
