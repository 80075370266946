/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: ($inuit-font-size-h1 / $inuit-global-font-size) * 1rem;
  line-height: $inuit-line-height-h1;
}

h2 {
  font-size: ($inuit-font-size-h2 / $inuit-global-font-size) * 1rem;
  line-height: $inuit-line-height-h2;
}

h3 {
  font-size: ($inuit-font-size-h3 / $inuit-global-font-size) * 1rem;
  line-height: $inuit-line-height-h3;
}

h4 {
  font-size: ($inuit-font-size-h4 / $inuit-global-font-size) * 1rem;
  line-height: $inuit-line-height-h4;
}

h5 {
  font-size: ($inuit-font-size-h5 / $inuit-global-font-size) * 1rem;
  line-height: $inuit-line-height-h5;
}

h6 {
  font-size: ($inuit-font-size-h6 / $inuit-global-font-size) * 1rem;
  line-height: $inuit-line-height-h6;
}
