.c-tooltip {
  background-color: color_var(bg, 1);
  border-radius: $global-radius;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.4);
  @include inuit-font-size($inuit-font-size-h5);
  font-weight: 400;
  text-align: left;
  padding: $inuit-global-spacing-unit-small;
  display: inline-block;
  z-index: 3 !important;

  &__container {
    padding-left: $inuit-global-spacing-unit-big;
    padding-bottom: $inuit-global-spacing-unit-big;
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 320px;

    @include mq($until: medium) {
      left: auto;
      bottom: auto;
      right: -45px;
      top: 40px;
    }
  }

  .c-icon:focus &__container,
  .c-icon:hover &__container {
    display: block;
  }
}
