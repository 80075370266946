.c-icons {
  &.has-v-sign::before {
    @include mq($until: medium) {
      width: 100%;
      left: 0;
      background-position: top;
    }
  }

  &__item {
    @include mq($until: medium) {
      margin-bottom: $inuit-global-spacing-unit-great;
    }

    &.wow {
      opacity: 0;
    }
  }

  &--spacing-none {
    padding-top: 0;
    padding-bottom: 0;

    .c-icons__content {
      margin-top: 0;
    }
  }

  &__title,
  &__subtitle {
    text-align: center;
  }

  &__content {
    margin-top: $inuit-global-spacing-unit-huge +
      $inuit-global-spacing-unit-large;
  }

  &__icon {
    height: $inuit-global-spacing-unit-huge;
    margin-bottom: $inuit-global-spacing-unit-great;

    &-title {
      &--small {
        @include inuit-font-size($inuit-font-size-h4 + 2px);
        font-weight: 500;
        white-space: nowrap;
        margin-bottom: $inuit-global-spacing-unit-great;
      }

      &--primary {
        color: color-var(font, 5);
      }
    }
  }
  padding-top: $inuit-global-spacing-unit-huge +
    $inuit-global-spacing-unit-small;
  padding-bottom: $inuit-global-spacing-unit-huge +
    $inuit-global-spacing-unit-small;
}
