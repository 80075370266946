.c-post-list-line {
  &__content {
    position: relative;
    margin-top: $inuit-global-spacing-unit-massive;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 2px;
      transform: translateX(-50%);
      background-color: color-var(bg, 15);
    }
  }

  &__flex-wrap {
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    margin-top: -24%;

    &:first-child {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 15%;
        width: 2px;
        transform: translateX(50%);
        background-color: color-var(bg, 1);
        z-index: 24;
      }
    }

    &--dotted {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 15%;
        width: $inuit-global-spacing-unit-great;
        height: $inuit-global-spacing-unit-great;
        border-radius: 50%;
        background-color: color-var(bg, 2);
        z-index: 10;
      }
    }

    &--left {
      padding-right: $inuit-global-spacing-unit-huge;

      &::after {
        right: 0;
        transform: translateX(50%);
      }
    }

    &--right {
      padding-left: $inuit-global-spacing-unit-huge;

      &::after {
        left: 0;
        transform: translateX(-50%);
      }
    }

    &--first {
      margin-top: 0;
    }

    &.last {
      &::before {
        content: '';
        position: absolute;
        top: 15%;
        right: 0;
        bottom: 0;
        width: 2px;
        transform: translateX(50%);
        z-index: 4;
        background-color: color-var(bg, 1);
      }

      &.c-post-list-line__item--right {
        &::before {
          right: auto;
          left: 0;
          transform: translateX(-50%);
        }
      }

    }

    &--hidden {
      position: relative;
      z-index: -1;
    }
  }

  @include mq($until:medium) {
    &__item {
      margin-top: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-great;
      width: 100%;
      background-color: color-var(bg, 1);

      &--right {
        padding-left: 0;
      }

      &--left {
        padding-right: 0;
      }

      &--dotted {
        &::after {
          top: -($inuit-global-spacing-unit-huge + $inuit-global-spacing-unit);
          right: auto;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &--hidden {
        display: none;
      }

      &.last,
      &:first-child {
        &::before {
          display: none;
        }
      }

      &:first-child {
        margin-top: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
      }
    }
  }
}
