.c-simple-buttons {
  margin: $inuit-global-spacing-unit-big 0;

  &--padding {
    padding: 0 $inuit-global-spacing-unit;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-large;
  }

  &__btn {
    margin-right: $inuit-global-spacing-unit-small;
  }

  &__btn-wrap {
    .c-simple-buttons__btn {
      margin-right: 0;
    }
  }

  &__btn-desc {
    padding-right: $inuit-global-spacing-unit-massive;
  }

  &__row {
    margin-bottom: $inuit-global-spacing-unit;
  }

  @include mq($until: medium) {
    &--mobile-center{
      text-align: center;
    }
    &__row {
      flex-wrap: wrap;
    }

    &__btn-desc {
      padding-right: 0;
    }

    &__btn-wrap {
      width: 100%;
    }

    .c-simple-buttons__btn-mobile-full.c-button {
      width: 100%;
      display: block;
    }
  }
}
