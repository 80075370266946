.c-faq {
  &__item {
    padding: $inuit-global-spacing-unit-great;
    margin-bottom: $inuit-global-spacing-unit-big;
    box-shadow: $global-shadow;

    @include mq($until: medium) {
      padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-big;
    }
  }

  &__title {
    color: color-var(font, 5);
    @include inuit-font-size($inuit-font-size-h3 - 2px);
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__description {
    p {
      margin-bottom: 0;
    }
  }
}
