/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding: 0 $inuit-global-spacing-unit-big;

  &--mobile-padding-none {
    padding: 0;
  }

  @include mq($until:medium) {
    &--mobile-none {
      padding: 0;
    }
  }
}

.o-bg-wave {
  position: relative;

  &__blue,
  & {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: calc(100% + #{$inuit-global-spacing-unit-massive});
      position: absolute;
      top: -$inuit-global-spacing-unit-massive;
      left: 0;
      background: url('../assets/images/svg/fala-gray.svg') no-repeat left top;
      z-index: -1;
    }
  }

  &__blue {
    &::before {
      position: absolute;
      top: -210px;
      background: url('../assets/images/svg/fala-blue.svg') no-repeat left top;
      z-index: -1;
    }
  }

  @include mq($until: medium) {
    &__blue {
      &::before {
        display: none;
      }
    }
  }
}
