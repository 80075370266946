/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-button {
  $self: &;
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: $inuit-global-spacing-unit-micro 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
  font-weight: $global-heading-font-weight;
  font-family: $global-heading-font-family;
  transition: $global-transition;
  font-size: 1rem;
  text-decoration: none;
  border: $global-border solid color-var(border);
  border-radius: $global-radius;
  color: color-var(font, 3);
  position: relative;
  background-color: transparent;

  &:before {
    @include absolutePseudoContent();
    @include absPosition100();
    background: color-var(bg, 8);
    opacity: 0;
    @include transition();
    pointer-events: none;
  }

  .c-label--file:hover &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;

    &:before {
      opacity: 0.2;
    }
  }

  /* Style variants
   ========================================================================== */

  &--primary {
    border-color: var(--color-primary-button);
    background-color: var(--color-primary-button);
    .c-icon * {
      fill: color-var(bg, 1);
    }

    &:hover,
    &:active,
    &:focus {
      color: color-var(font, 3);
    }

    &#{$self}--tertiary {
      &:hover,
      &:active,
      &:focus {
        color: color-var(font, 5);
      }
    }
  }

  &--secondary {
    border-color: color-var(error);
    background-color: color-var(error);
  }

  &--tertiary {
    border-color: color-var(border, 2);
    background-color: transparent;
    color: color-var(font, 5);

    &#{$self}--bg-white {
      background-color: color-var(bg, 1);
    }

    .c-icon * {
      fill: color-var(font, 5);
    }

    &:hover,
    &:active,
    &:focus {
      &::before {
        opacity: 0.05;
      }
    }
  }

  &--error {
    border-color: color-var(error);
    background-color: color-var(error);
  }

  &--success {
    border-color: color-var(success);
    background-color: color-var(success);
  }

  &--black {
    border-color: color-var(func, 6);
    background-color: transparent;
    color: color-var(font, 1);

    .c-icon * {
      fill: color-var(font, 1);
    }
  }

  &--quaternary {
    border-color: color-var(border, 2);
    background-color: color-var(bg, 2);
    color: color-var(font, 3);
  }

  &--full {
    width: 100%;
  }

  &--fixed {
    min-width: $inuit-global-baseline * 50;
  }

  &--filter {
    border: $global-border solid color-var(border, 1);
    text-align: center;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
    border-radius: $global-radius-input;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great
      rgba(0, 0, 0, 0.128975);
    background-color: color-var(bg, 1);
    color: color-var(font, 5);
    font-weight: 400;
    transition: all 0.2s ease-out;

    &:hover,
    &.is-active {
      box-shadow: inset 0 $inuit-global-spacing-unit-small
        $inuit-global-spacing-unit-micro rgba(0, 0, 0, 0.16);
      background-color: color-var(bg, 2);
      color: color-var(font, 3);

      &::before {
        display: none;
      }
    }
  }

  &--icon-default {
    .c-button__text {
      display: inline-block;
      vertical-align: middle;
      color: color-var(font, 5);
    }

    .c-button__icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: $inuit-global-spacing-unit-tiny;
      line-height: 1;
    }

    &:hover,
    &.is-active {
      background-color: color-var(bg, 2);

      .c-button__text {
        color: color-var(font, 3);
      }

      .c-icon * {
        fill: color-var(bg, 1);
      }
    }
  }

  &--alternative {
    background-color: color-var(bg, 1);
    color: color-var(font);

    &:before {
      display: none;
    }

    &.c-button--primary {
      color: color-var(bg, 2);

      .c-icon * {
        fill: color-var(bg, 2);
      }
    }

    &.c-button--secondary {
      color: color-var(bg, 3);

      .c-icon * {
        fill: color-var(bg, 3);
      }
    }

    &.c-button--tertiary {
      color: color-var(bg, 4);

      .c-icon * {
        fill: color-var(bg, 4);
      }
    }

    &.c-button--error {
      color: color-var(error);

      .c-icon * {
        fill: color-var(error);
      }
    }

    &.c-button--success {
      color: color-var(success);

      .c-icon * {
        fill: color-var(success);
      }
    }

    &.c-button--black {
      border-color: color-var(func, 3);
      background-color: transparent;
      color: color-var(font, 3);

      .c-icon * {
        fill: color-var(font, 3);
      }
    }

    &:hover,
    &:active,
    &:focus {
      // color: color-var(font, 3);
    }
  }

  &--with-icon {
    // position: relative;
    // padding-left: $inuit-global-spacing-unit-huge !important;
    // padding-right: $inuit-global-spacing-unit-huge !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .c-icon * {
      fill: color-var(font, 3);
      margin-left: $inuit-global-spacing-unit-tiny;
    }

    &-right {
      .c-icon {
        margin-left: 0;
        margin-right: $inuit-global-spacing-unit-tiny;
      }
    }

    &.c-button--alternative {
      .c-icon * {
        fill: color-var(font);
      }
    }

    .c-icon img {
      margin-right: $inuit-global-spacing-unit-tiny;
      margin-left: 0;
    }
  }

  &--disabled {
    pointer-events: none;
    color: color-var(font, 3);
    border-color: color-var(font, 4);
    background-color: color-var(font, 4);
  }

  &--square {
    padding: $inuit-global-spacing-unit-tiny 2px;
    min-width: $inuit-global-spacing-unit-huge;
    margin: 0 $inuit-global-spacing-unit-micro;
  }

  &--pagination {
    @include mq($until: medium) {
      &-prev {
        margin: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-micro 0 0;
      }

      &-next {
        margin: $inuit-global-spacing-unit-great 0 0 $inuit-global-spacing-unit-micro;
      }
    }
  }

  /* Size variants
   ========================================================================== */

  &--small {
    padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-small;
  }

  &--tiny {
    padding: $inuit-global-spacing-unit-micro $inuit-global-spacing-unit-small;
  }

  &--middle{
    padding: $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-large;
  }

  &--large {
    padding: 0.75rem 1.5rem;
  }

  &--vertical-large {
    min-width: 170px;
  }

  @include mq($until: small) {
    &--mobile-full {
      width: 100%;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  &--with-arrow {
    position: relative;
    padding-right: 20px !important;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 100%;
      background: url(../assets/images/svg/triangle_white.svg) no-repeat center
        right 6px;
    }
  }

  .c-buttons--smaller & {
    border: 1px solid color-var(border, 1);
    font-family: Roboto !important;
    padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-great;
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    font-weight: 400;
    margin-top: $inuit-global-spacing-unit !important;
    margin-bottom: $inuit-global-spacing-unit !important;
  }
}
