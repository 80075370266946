.c-table-pricelist {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: stretch;
  width: 70%;
  margin: $inuit-global-spacing-unit-massive auto 0 auto;

  .c-single-inner-content__container & {
    width: calc(70% + 150px);
  }

  &__single-column {
    flex-grow: 1;
    margin: 0 $inuit-global-spacing-unit;
    padding: $inuit-global-spacing-unit 0;
    border-radius: $global-radius;
    border: $global-border solid color-var(font, 4);
  }

  &__option-wrapper {
    padding: 0 $inuit-global-spacing-unit-tiny;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__single-option {
    flex-grow: 1;
    margin: 0 $inuit-global-spacing-unit-tiny;
  }

  &__title {
    display: block;
    margin-bottom: $inuit-global-spacing-unit-small;
    font-family: $global-heading-font-family;
    font-weight: $global-strong-font-weight;
  }

  &__label {
    display: block;
    margin-bottom: $inuit-global-spacing-unit-small;
    width: 100%;
    padding: $inuit-global-spacing-unit-micro;
    border-radius: $global-radius;
    background-color: color-var(bg, 6);
  }

  @include mq($until: medium) {
    width: 100%;
    margin: $inuit-global-spacing-unit-huge auto 0 auto;
    flex-wrap: wrap;

    &__single-column {
      margin-top: $inuit-global-spacing-unit-big;
    }

    &__option-wrapper {
      flex-wrap: wrap;
    }

    &__single-option {
      margin-bottom: $inuit-global-spacing-unit;
    }
  }

}
