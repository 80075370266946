.c-form-basic {
  * {
    z-index: auto;
  }

  .c-input,
  .c-textarea {
    box-shadow: none !important;
  }

  .c-select__title {
    box-shadow: none !important;
    color: color-var(font, 1);
    border-color: color-var(border, 1);
  }

  .o-flex__item {
    padding: 0 $inuit-global-spacing-unit;
  }

  .c-label {
    label {
      position: initial;
    }

    .c-select--disabled {
      display: none !important;
    }

    .c-textarea {
      height: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-huge;
    }

    &__title {
      margin-bottom: $inuit-global-spacing-unit-tiny;

      &--strong {
        font-weight: $global-heading-font-weight;
      }
    }

    &--aditional-padding {
      margin-left: $inuit-global-spacing-unit-big;
    }
  }

  &__multiple-checkbox {
    .c-label__container {
      padding-left: $inuit-global-spacing-unit-great;

      &--main-input {
        padding-left: 0;
      }
    }
  }

  &__submit-wrapper {
    text-align: center;
  }

  &__btn-wrap {
    position: relative;
    display: inline-block;

    .wpcf7-spinner {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      right: $inuit-global-spacing-unit-micro;
      transform: translateY(-50%);
    }
  }

  //wrapper file input
  .wrapper-file-input {
    &.hidden {
      display: none;
    }
  }

  //input valid
  .wpcf7-form-control-wrap,
  .c-input__container,
  .wpcf7-form-control {
    position: initial
  }

  .wpcf7-not-valid {
    @extend .c-input--error;
  }

  .wpcf7-not-valid-tip {
    padding-left: 0;
    position: absolute;
    top: 1px;
    right: 0;
    margin-top: 0;
    padding-left: $inuit-global-spacing-unit-great;
    @include inuit-font-size($inuit-font-size-h5);
    color: color-var(error);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      vertical-align: middle;
      width: $inuit-global-spacing-unit-big;
      height: $inuit-global-spacing-unit-big;
      background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 62 (101010) - https://sketch.com --%3E%3Ctitle%3E2FDF1B5C-1FAD-41A5-9EDD-FADD999A28F2%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Asset-Artboard-Page' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='atom/ico/contact/mappin-atom/ico/info'%3E%3Cg id='atom/ico/info'%3E%3Cpath d='M12,2 C6.47747628,2 2,6.47747628 2,12 C2,17.523153 6.47747628,22 12,22 C17.523153,22 22,17.5237822 22,12 C22,6.47747628 17.5230831,2 12,2 Z M12,20.1249694 C7.51252561,20.1249694 3.87503059,16.4874744 3.87503059,12 C3.87503059,7.51252561 7.51252561,3.87503059 12,3.87503059 C16.4874744,3.87503059 20.1249694,7.51252561 20.1249694,12 C20.1249694,16.4874744 16.4874744,20.1249694 12,20.1249694 Z M12.0006293,6.38560552 C11.2844008,6.38560552 10.7424752,6.7593811 10.7424752,7.36311326 L10.7424752,12.9005992 C10.7424752,13.5049606 11.2843309,13.8774777 12.0006293,13.8774777 C12.6993784,13.8774777 13.2587833,13.4893691 13.2587833,12.9005992 L13.2587833,7.36311326 C13.2587134,6.77371405 12.6993784,6.38560552 12.0006293,6.38560552 Z M12.0006293,15.1250044 C11.3125074,15.1250044 10.7524733,15.6850385 10.7524733,16.3737196 C10.7524733,17.0612122 11.3125074,17.6212463 12.0006293,17.6212463 C12.6887511,17.6212463 13.2481559,17.0612122 13.2481559,16.3737196 C13.248086,15.6849686 12.6887511,15.1250044 12.0006293,15.1250044 Z' id='Shape-Copy' fill='%23CA383A'%3E%3C/path%3E%3Cpolygon id='Path' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

  }

  //checkbox custom
  .c-checkbox-custom {
    padding-left: $inuit-global-spacing-unit-big;

    .wpcf7-not-valid-tip {
      position: relative;
      display: block;
      top: 0;
      right: auto;
      margin: $inuit-global-spacing-unit-tiny 0 0 (-$inuit-global-spacing-unit);
    }

    span,
    label {
      display: block;
      cursor: pointer;
    }

    .c-checkbox-input {
      position: absolute;
      top: 0;
      left: -$inuit-global-spacing-unit-great;
      display: inline-block;
      vertical-align: middle;
      width: $inuit-global-spacing-unit-big;
      height: $inuit-global-spacing-unit-big;
      border-radius: $global-radius;
      border: $global-border solid color-var(border, 1);
      background-color: color-var(bg, 1);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-big;

      &[aria-invalid="true"] {
        border-color: color-var(error);
      }

      &:not(:checked) {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 96 96' height='96px' id='square_check' version='1.1' fill='%23ffffff' viewBox='0 0 96 96' width='96px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M80,4H16C9.37,4,4,9.37,4,16v64c0,6.63,5.37,12,12,12h64c6.63,0,12-5.37,12-12V16C92,9.37,86.63,4,80,4z M84,80 c0,2.21-1.79,4-4,4H16c-2.21,0-4-1.79-4-4V16c0-2.21,1.79-4,4-4h64c2.21,0,4,1.79,4,4V80z'/%3E%3Cpath fill='%23ffffff' d='M66.385,35.272c-1.562-1.562-4.095-1.562-5.656,0L43.757,52.243l-8.485-8.485c-1.562-1.562-4.095-1.562-5.657,0 c-1.562,1.562-1.562,4.095,0,5.657l11.312,11.312c1.562,1.562,4.095,1.562,5.657,0l19.799-19.799 C67.947,39.367,67.947,36.835,66.385,35.272z'/%3E%3C/svg%3E");
      }

      &:checked {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg enable-background='new 0 0 96 96' height='96px' id='square_check' version='1.1' fill='%23ffffff' viewBox='0 0 96 96' width='96px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M80,4H16C9.37,4,4,9.37,4,16v64c0,6.63,5.37,12,12,12h64c6.63,0,12-5.37,12-12V16C92,9.37,86.63,4,80,4z M84,80 c0,2.21-1.79,4-4,4H16c-2.21,0-4-1.79-4-4V16c0-2.21,1.79-4,4-4h64c2.21,0,4,1.79,4,4V80z'/%3E%3Cpath fill='%23000000' d='M66.385,35.272c-1.562-1.562-4.095-1.562-5.656,0L43.757,52.243l-8.485-8.485c-1.562-1.562-4.095-1.562-5.657,0 c-1.562,1.562-1.562,4.095,0,5.657l11.312,11.312c1.562,1.562,4.095,1.562,5.657,0l19.799-19.799 C67.947,39.367,67.947,36.835,66.385,35.272z'/%3E%3C/svg%3E");
      }

      &:focus {
        outline: none;
      }
    }
  }

  .wpcf7 form {

    &.invalid,
    &.failed,
    &.sent {
      .wpcf7-response-output {
        position: relative;
        bottom: auto;
        left: auto;
        display: block;
        width: 100%;
        margin: $inuit-global-spacing-unit-big 0 0;
        border: $global-border solid color-var(error);
        padding: $inuit-global-spacing-unit-small;
        border-radius: $global-radius;
        font-size: 14px;
        font-weight: 400;
        background-color: color-var(bg, 1);
        color: color-var(error);
      }

    }

    &.sent {
      .wpcf7-response-output {
        color: color-var(font, 7);
        border-color: color-var(font, 7);
      }
    }

  }

  @include mq($until:medium) {

    &__multiple-checkbox {
      .c-label__container {
        padding-left: 0;
      }
    }

    .o-flex__item {
      padding: 0;
      width: 100%;
    }

    .wpcf7-not-valid-tip {
      top: auto;
      right: auto;
      left: 0;
      padding-left: $inuit-global-spacing-unit-large;
      bottom: -$inuit-global-spacing-unit-large;
    }
  }
}
