.c-faq-accordion {
  &__container {
    padding: $inuit-global-spacing-unit-big;
  }

  &__item {
    background-color: color_var(bg, 5);
    padding: $inuit-global-spacing-unit-big;
    margin-bottom: $inuit-global-spacing-unit-big;
    @include mq($from: medium) {
      padding: $inuit-global-spacing-unit-large;
    }
  }

  &__text {
    height: $inuit-line-height-h5;
    margin-bottom: $inuit-global-spacing-unit-small;
    overflow: hidden;
  }

  &__item.is-expand &__text {
    height: auto;
  }

  &__roll, &__unroll {
    color: color-var(font, 5);
    @include inuit-font-size($inuit-font-size-h5);
    display: block;
    cursor: pointer;
  }
}
