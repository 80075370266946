.c-slider-simple {
  $self: &;
  position: relative;
  padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-huge +
    $inuit-global-spacing-unit-big;

  &__title {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit-huge;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $inuit-global-spacing-unit-small;
    text-align: center;

    &--more-space {
      padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit;
    }
  }

  .splide__arrows {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
  }

  .splide__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .c-vistula-blog + & {
      padding: 30px 0 !important;
      background: #fff;
      border-radius: 4px;

      @include mq($from: large) {
        width: calc(25% - 24px) !important;
        margin: 40px 12px;
      }

      img {
        max-height: 100px;
      }
    }
  }

  .splide__track {
    .c-vistula-blog + & {
      @include mq($until: large) {
        margin: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-big $inuit-global-spacing-unit-huge;
      }
    }
  }

  .splide__arrow {
    width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    line-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
    border-radius: 50%;
    border: 0;
    opacity: 1;
    text-align: center;
    background-color: color-var(bg, 2);
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great
      rgba(0, 0, 0, 0.181149);
    cursor: pointer;
    pointer-events: all;
    z-index: 88;

    &:disabled {
      opacity: 0.3;
    }

    &--prev {
      left: -($inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big);
    }

    &--next {
      right: -($inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big);
    }

    svg {
      fill: color-var(bg, 1);
    }
  }

  .splide__pagination {
    bottom: -$inuit-global-spacing-unit-big;

    &__page {
      margin: $inuit-global-spacing-unit-micro;
      display: inline-block;
      @include square-style(15px);
      border-radius: 50%;
      background: color-var(bg, 2);
      cursor: pointer;
      opacity: 0.2;
      width: $inuit-global-spacing-unit-huge;
      height: $inuit-global-spacing-unit-tiny - 2;
      border-radius: $global-radius;

      &.is-active {
        opacity: 1;
        background: color-var(bg, 2);
        transform: none;
      }
    }
  }

  //image carousel
  &--carousel {
    padding: $inuit-global-spacing-unit-huge 0;
  }

  &__img-carousel {
    box-shadow: 0 $inuit-global-spacing-unit-tiny
      $inuit-global-spacing-unit-small $inuit-global-spacing-unit-micro
      rgba(0, 0, 0, 0.4);
    border-radius: $global-radius;
    max-height: 100%;
    width: auto;
  }

  &__img-container {
    padding: 0 $inuit-global-spacing-unit-micro 0 $inuit-global-spacing-unit-micro;

    @include mq($from: large) {
      padding: 0 $inuit-global-spacing-unit-small 0 $inuit-global-spacing-unit-small;
    }
  }

  &__item-carousel {
    padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-small;
    border-radius: $global-radius;
    overflow: hidden;
  }

  //change arrow color
  &--white-arrow {
    .splide__arrow {
      background-color: color-var(bg, 1);

      svg {
        fill: color-var(bg, 2);
      }

      &:hover {
        background-color: color-var(bg, 2);

        svg {
          fill: color-var(bg, 1);
        }
      }
    }
  }

  &--post {
    padding: $inuit-global-spacing-unit-huge 0 !important;

    .splide__slide {
      text-align: left;
    }

    @include mq($until: medium) {
      .c-slider-simple__item {
        padding: 0;
      }
    }
  }

  &--overflow {
    .splide__track {
      overflow: visible !important;
    }
    .c-slider-simple__item:not(.is-visible) {
      opacity: 0.2;
    }
  }
  //change arrow position
  &--arrow-top {
    .splide__arrows {
      top: 20%;
    }

    .splide__arrow {
      &--prev {
        left: -$inuit-global-spacing-unit;
      }

      &--next {
        right: -$inuit-global-spacing-unit;
      }
    }

    #{$self}__items {
      margin: 0 -$inuit-global-spacing-unit-big;
    }
  }

  &--white-pagination {
    /* stylelint-disable-next-line selector-class-pattern */
    .splide__pagination__page {
      &.is-active {
        background-color: color_var(bg, 1);
      }

      &:hover, :focus {
        background-color: color_var(bg, 1);
      }
    }
  }

  @include mq($until: 'medium') {
    padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-big;
    overflow: hidden;

    .splide__arrows {
      .splide__arrow {
        width: $inuit-global-spacing-unit-huge;
        height: $inuit-global-spacing-unit-huge;

        &--prev {
          left: -$inuit-global-spacing-unit-large;
        }

        &--next {
          right: -$inuit-global-spacing-unit-large;
        }
      }
    }

    &--arrow-mobile-top {
      padding: $inuit-global-spacing-unit-huge $inuit-global-spacing-unit-big;

      .splide__arrows {
        top: $inuit-global-spacing-unit !important;

        .splide__arrow {
          width: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;
          height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit;

          &--prev {
            left: -$inuit-global-spacing-unit-big;
          }

          &--next {
            right: -$inuit-global-spacing-unit-big;
          }
        }
      }
    }

    &--arrow-top {
      padding: $inuit-global-spacing-unit-huge 0;

      #{$self}__items {
        margin: 0 -$inuit-global-spacing-unit;
        padding-left: $inuit-global-spacing-unit-big;
      }

      #{$self}__item {
        padding: $inuit-global-spacing-unit-big $inuit-global-spacing-unit-tiny;
      }

      .splide__arrows {
        .splide__arrow {
          &--prev {
            left: $inuit-global-spacing-unit-small;
          }

          &--next {
            right: $inuit-global-spacing-unit-small;
          }
        }
      }
    }

    &--hide-pagination-mobile .splide__pagination {
      display: none;
    }

    &--white-arrow-mobile .splide__arrow {
      background-color: color_var(bg, 1);
      box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);

      * {
        fill: color_var(font, 1);
      }
    }
  }

  &__link {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
