.c-course-information {
  position: relative;

  &__container {
    padding: $inuit-global-spacing-unit-great 0 $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small $inuit-global-spacing-unit-huge;
    border-radius: $global-radius;
    box-shadow: 0 $inuit-global-spacing-unit $inuit-global-spacing-unit-great rgba(0, 0, 0, 0.128975);
    background-color: color-var(bg, 1);
  }

  &__specialization-wrapper {
    padding-right: $inuit-global-spacing-unit-great;
  }

  &__title {
    margin-bottom: $inuit-global-spacing-unit-great;
  }

  &__subtitle {
    margin-bottom: $inuit-global-spacing-unit-huge;
    font-family: $global-body-font-family;
    font-weight: $global-strong-font-weight;
  }

  &__img-wrapper {
    position: relative;
    display: block;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: 0 0 $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large color-var(main, 1) inset;
      z-index: 10;
      opacity: 0.17;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__img-icon {
    position: absolute;
    bottom: -$inuit-global-spacing-unit-massive;
    right: -$inuit-global-spacing-unit-great;
    z-index: 10;

    & * path {
      fill: color-var(bg, 2);
    }
  }

  &__btn {
    margin: $inuit-global-spacing-unit-large $inuit-global-spacing-unit-tiny 0 0;
  }

  @media (max-width: 79.99em) {
  // @include mq($until:xlarge) {
    &__img-wrapper {
      overflow: hidden;
    }

    &__img-icon {
      bottom: $inuit-global-spacing-unit-big;

      svg {
        width: 400px;
        height: 326px;
      }
    }
  }

  @include mq($until: medium) {
    &__container {
      padding: $inuit-global-spacing-unit 0 0;
    }

    &__specialization-wrapper,
    &__title {
      padding: 0 $inuit-global-spacing-unit;
    }

    &__img-wrapper {
      margin-top: $inuit-global-spacing-unit-great;
      overflow: hidden;
    }

    &__img-icon {
      svg {
        width: 300px;
        height: 226px;
      }
    }
  }
}
