.flexmasonry {
  display: flex;
  flex-wrap: wrap;

  @include mq($from: medium) {
    flex-direction: column;
    align-content: space-between;
    height: 1700px;

    .c-post-filter__item {
      width: 32%;
    }

    .c-post-filter__item:nth-child(3n+1) {
      order: 1;
    }

    .c-post-filter__item:nth-child(3n+2) {
      order: 2;
    }

    .c-post-filter__item:nth-child(3n) {
      order: 3;
    }

    &::before,
    &::after {
      content: '';
      flex-basis: 100%;
      width: 0;
      order: 2;
    }
  }

  &--two-items {
    align-content: flex-start;

    .c-post-filter__item:first-child {
      margin-right: 2%;
    }
  }
}
