.c-member-box {
  position: relative;
  width: 100%;
  display: block;
  padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-great $inuit-global-spacing-unit-tiny $inuit-global-spacing-unit-great;

  &__inner-wrapper {
    padding: $inuit-global-spacing-unit-massive $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
    border-radius: $global-radius;
    filter: drop-shadow(0 $inuit-global-spacing-unit-small $inuit-global-spacing-unit-small rgba(0, 0, 0, 0.12));
    background-color: color-var(bg);
  }


  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
    height: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-big;
    border-radius: 50%;
    border: $inuit-global-spacing-unit-tiny - 2 solid color-var(bg);
    background-color: color-var(bg, 6);
    overflow: hidden;

    svg {
      margin-top: $inuit-global-spacing-unit-small;
    }
  }

  &__name-wrapper {
    margin-bottom: $inuit-global-spacing-unit-tiny;
    height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
    line-height: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small;
  }

  &__name {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;
  }
}
