.c-contact-form {
  &__inner-wrap {
    padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small $inuit-global-spacing-unit-great;
    background-color: color-var(bg, 5);
    border-radius: $global-radius;
  }

  &__title {
    @include mq($from: medium) {
      margin-bottom: $inuit-global-spacing-unit-great;
    }
  }

  &__form {
    &--smaller {
      max-width: 770px;
      margin: 0 auto;
    }
  }

  @include mq($until:medium) {
    &--mobile-full {
      .c-contact-form__container {
        padding: 0;
      }
    }

    &__inner-wrap {
      padding: $inuit-global-spacing-unit-huge + $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
    }
  }

  .c-input {
    border-color: color_var(func, 5);

    &__container--file {
      margin-bottom: $inuit-global-spacing-unit-big;
    }

    &__container textarea {
      border-color: color_var(func, 5);
    }
  }

  .c-checkbox-custom .c-checkbox-input {
    border-color: color_var(func, 5);
  }
}
